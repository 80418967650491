html,body {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #000000;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Poppins-Regular", sans-serif!important;  
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins-Bold.ttf'); 
}
@font-face {
  font-family: 'Fonseca-Regular';
  src: url('./fonts/Fonseca-Light.otf'); 
} 
@font-face {
  font-family: 'Madera-Bold';
  src: url('./fonts/Madera Bold.ttf'); 
}
 
@font-face {
  font-family: 'Madera-Regular';
  src: url('./fonts/Madera Regular.ttf');
}

@font-face {
  font-family: 'Madera-Italic';
    src: url('./fonts/Madera Italic.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins-Bold.ttf'); 
}


@font-face {
  font-family: 'Poppins-semi-Bold';   
  src: url('./fonts/Poppins-SemiBold.ttf'); 
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('./fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Italic';
    src: url('./fonts/Poppins-SemiBoldItalic.ttf');
}

.scroll-container {
  position: fixed;
  will-change: transform;
  right: 0;
  left: 0;
}