html {
    scroll-behavior: smooth;
}

html,
body,
ul,
ol {
    margin: 0;
    padding: 0;
}

// Colors Theme
$purpleColor: #43273b;
$redColor: #fd4a5c;
$orangeColor: #FF5E00;
$yellowColor: #FFCD00;
$lightblue: #EEF4FF;
$lightgrey: #d6d6d6;
$whiteColor: #ffffff;
$lightBlack: #141414;
$blackColor: #000000;
$blueColor: #002554;
$greenColor: #00B398;
$lightGreen: #e4f7f5;

.blueColor {
    color: $blueColor;
}

.greenColor {
    color: $greenColor;
}

.lightGreenColor {
    color: $lightGreen;
}

.purpleColor {
    color: $purpleColor;
}

.redColor {
    color: $redColor;
}

.orangeColor {
    color: $orangeColor;
}

.yellowColor {
    color: $yellowColor;
}

.whiteColor {
    color: $whiteColor;
}

.lightBlack {
    color: $lightBlack;
}

.blackColor {
    color: $blackColor;
}

.lightgrey {
    color: $lightgrey;
}

// Color theme for background colors
.blurColorBG {
    background-color: $blueColor;
}

.greenColorBG {
    background-color: $greenColor;
}

.lightGreenBG {
    background-color: $lightGreen;
}

.purpleColorBG {
    background-color: $purpleColor;
}

.redColorBG {
    background-color: $redColor;
}

.oragneBG {
    background-color: $orangeColor;
}

.yellowColorBG {
    background-color: $yellowColor;
}

.whiteColorBG {
    background-color: $whiteColor;
}

.lightBlackBG {
    background-color: $lightBlack;
}

.blackColorBG {
    background-color: $blackColor;
}

.lightgreyBG {
    color: $lightgrey;
}

// Button Themes
@mixin commonBtn($backgroundColor, $borderColor, $textcolor) {
    display: inline-block !important;
    background-color: $backgroundColor !important;
    border: 1px solid $borderColor !important;
    padding: 5px 20px 5px 20px !important;
    line-height: 2rem !important;
    color: $textcolor !important;
    outline: none !important;
    text-align: center !important;
    text-transform: uppercase;
    font-family: 'Poppins-Bold';
    font-size: 15px !important;
    border-radius: 50px !important;
    position: relative;
    cursor: pointer;
    transition: all .6s;
    overflow: hidden;
    z-index: 99;

    &:hover {
        color: $textcolor;
    }
}

.whiteColorBtn {
    @include commonBtn(transparent, $whiteColor, $whiteColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($whiteColor, $whiteColor, $blueColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($whiteColor, $whiteColor, $blueColor);

        &:before {
            left: 0;
        }
    }
}

.greenColorBtn {
    @include commonBtn(transparent, $greenColor, $greenColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($greenColor, $greenColor, $whiteColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($greenColor, $greenColor, $whiteColor);

        &:before {
            left: 0;
        }
    }
}

.greenBgColorBtn {
    @include commonBtn($greenColor, $greenColor, $whiteColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($yellowColor, $yellowColor, $whiteColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($yellowColor, $yellowColor, $whiteColor);

        &:before {
            left: 0;
        }
    }
}

.redColorBtn {
    @include commonBtn(transparent, $redColor, $redColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($redColor, $redColor, $whiteColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($redColor, $redColor, $whiteColor);

        &:before {
            left: 0;
        }
    }
}

.yellowColorBtn {
    @include commonBtn($yellowColor, transparent, $blackColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($greenColor, $greenColor, $whiteColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($greenColor, $greenColor, $whiteColor);

        &:before {
            left: 0;
        }
    }

}
 
a:hover { 
    text-decoration: none!important;
}
.redBorderWhiteBtn {
    @include commonBtn($redColor, $whiteColor, $whiteColor);

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        @include commonBtn($whiteColor, $whiteColor, $redColor);
        position: absolute;
        transition: all .6s;
        z-index: -1;
        top: 0;
        left: -100%;
    }

    &:hover {
        @include commonBtn($whiteColor, $whiteColor, $redColor);

        &:before {
            left: 0;
        }
    }
}

.bottomBorder {
    border-radius: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    // border-bottom: 2px solid $greenColor!important;
    padding-bottom: 5px !important;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    a{
        color: $blackColor;
        text-decoration: none;
        &:hover {
            color: $yellowColor;
            text-decoration: none;
        }
    }
    &:before {
        content: '';
        position: absolute;
        bottom: -0.3em;
        width: 0%;
        height: 0.13em;
        background: $yellowColor;
        left: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -0.3em;
        width: 100%;
        height: 0.13em;
        background: $greenColor;
        left: 0;
        z-index: 1;
    }

    &:hover {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: $yellowColor;

        &:before {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            width: 100%;
        }
    }
}

.bottomWhiteBorder {
    border-radius: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    color: $whiteColor;
    padding-bottom: 5px !important;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:before {
        content: '';
        position: absolute;
        bottom: -0.3em;
        width: 0%;
        height: 0.13em;
        background: $yellowColor;
        left: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -0.3em;
        width: 100%;
        height: 0.13em;
        background: $whiteColor;
        left: 0;
        z-index: 1;
    }

    &:hover {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: $yellowColor;

        &:before {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            width: 100%;
        }
    }
}


button:focus {
    outline: none !important;
}


// Font Styles
.fonsecaBold {
    font-family: 'Poppins-Bold';
    src: url('./../fonts/Poppins-Bold.ttf');
}

.fonsecaRegular {
    font-family: 'Fonseca-Regular';
    src: url('./../fonts/Fonseca-Light.otf');
}

.poppinsBold {
    font-family: 'Poppins-Bold';
    src: url('./../fonts/Poppins-Bold.ttf');
}

.poppinsSemiBold {
    font-family: 'Poppins-semi-Bold';
    src: url('./../fonts/Poppins-SemiBold.ttf');
}

.poppinsRegular {
    font-family: 'Poppins-Regular';
    src: url('./../fonts/Poppins-Regular.ttf');
}

.poppinsItlaic {
    font-family: 'Poppins-Italic';
    src: url('./../fonts/Poppins-SemiBoldItalic.ttf');
}

// Common Styles
@mixin flex ($flexdirection, $justifycontent, $alignitems) {
    display: flex;
    flex-direction: $flexdirection;
    justify-content: $justifycontent;
    align-items: $alignitems;
}

@mixin flex-column-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@mixin bg-cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin bg-norepeat {
    background-repeat: no-repeat;
    background-position: center;
}

.padding-zero {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.margin-zero {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.btn.focus {
    box-shadow: none;
}

.btn.focus,
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.common-sec {
    padding: 100px 75px;
}

.commonSec-spacing {
    padding: 100px;
}

.common-heading {
    line-height: 60px;
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 40px;
    // text-transform: uppercase;
}

.common-para {
    font-size: 15px;
    line-height: 26px;
    font-family: 'Poppins-Regular';
}

.sub-heading {
    font-family: 'Poppins-Bold';
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
}

// modal pop for the thank you message
.modal-dialog {
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%) !important;
    -ms-transform: translate(0, -50%) !important;
    -webkit-transform: translate(0, -50%) !important;
    margin: auto 5%;
}

.modal-footer {
    justify-content: center !important;
}

.modal-header,
.modal-footer {
    border: none !important;
}

.modal-content {
    border-radius: 20px !important;
    text-align: center;
}

.modal-body h2 {
    font-size: 1.8rem;
}


.modal-backdrop {
    opacity: 0.5;
    z-index: -1 !important;
    transition: 2s ease-in;
}

.modal-dialog {
    max-width: 700px !important;
    border: 1px solid $greenColor;
    border-radius: 20px;
    transition: 2s ease-in;
}


// form-error
.form-error {
    color: $greenColor;
    width: 100%;
    display: flex;
    position: absolute;
    bottom: -15px;
    font-size: 12px;
    padding: 0.1rem 0.85rem;
}





// Common Pagination style
.pagination {
    padding-top: 30px;
    border-radius: 0rem;

    .visually-hidden {
        display: none;
    }

    .page-item {
        &:first-child {
            .page-link {
                margin-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:last-child {
            .page-link {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: auto;
            }
        }

        .page-link {
            border: none;
            padding: 0rem 0rem;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $blackColor !important;
            opacity: 0.5;

            &:hover {
                background-color: transparent;
            }

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }

        &.active {

            .page-link {
                border: none;
                padding: 0rem 0rem;
                z-index: 1;
                color: $whiteColor !important;
                opacity: 1;
                background-color: $greenColor;
                border-color: $greenColor;
                border-radius: 50px;
            }
        }
    }
}

// Rahul Navbar Style Start
.rahul-navbar {
    &.navbar {
        padding: 0;
    }

    &.fixed-top {
        // position: absolute;
    }

    &.bg-light {
        height: 100px;
        background-color: transparent !important;
        transition: all 0.5s ease;

        .rahul-navBG {
            // a {
            //     color: $whiteColor;
            //     &:hover {
            //         color: $yellowColor;
            //     }
            //     &:active {
            //         color: $yellowColor;
            //     }
            // }
        }

        .navbar-brand {
            .transparentLogo {
                display: block;
            }

            .bgLogo {
                display: none;
            }
        }
    }

    &.navactive.bg-light {
        height: 75px;
        background-color: #ffffff !important;
        box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
        transition: all 0.5s ease;

        .navbar-brand {
            img {
                width: 225px;
                transition: all 0.5s ease;
            }
        }

        .nav-link.active,
        .nav-link:hover {
            color: $yellowColor;
        }


        .rahul-navBG {
            a {
                color: $blackColor;

                &:hover {
                    color: $yellowColor;
                }
            }
        }

        .navbar-brand {
            .transparentLogo {
                display: none;
            }

            .bgLogo {
                display: block;
            }
        }
    }

    .navbar-brand {

        // background-color: $blueColor;
        // padding: 20px 10px;
        // margin-right: 0rem;
        img {
            width: 230px;
            transition: all 0.5s ease;
        }
    }

    .rahul-navBG.container {
        max-width: 1300px;
        padding: 0;
    }
    .dropdown-menu {
        .nav-link { 
            color: $blackColor;
        }
    }
    .dropdown-item.active, .dropdown-item:active {
        color: $yellowColor;
        text-decoration: none;
        background-color: transparent;
    }
    .dropdown-item:focus, .dropdown-item:hover {
        color: $yellowColor;
        text-decoration: none;
        background-color: transparent;
    }
    .nav-link {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        font-family: 'Poppins-Regular';
        position: relative;
        text-transform: uppercase;
        padding: 10px 12px;
        color: $whiteColor;

        &:hover {
            color: $yellowColor;
        }

        &.active {
            color: $yellowColor;
        }

        &.yellowColorBtn {
            margin-left: 0px;

            svg {
                margin: 0px 0px 0px;
                // margin: 0px 5px 0px;
                animation-name: heart;
                animation-duration: 4s;
                animation-delay: 0.3s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-out;
                transition: all 0.3s;
            }
        }
    }

}

// RCT Footer Style
.rct-footer-sec {
    position: relative;
    z-index: 7;

    .footer-links-div {
        @include flex (column, center, center);
    }

    .subscribe-flex {
        @include flex (column, center, center);
    }

    &::before {
        position: absolute;
        content: '';
        background-image: url('./../assets/footerBottomimg.svg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        width: 350px;
        height: 350px;
        bottom: 0;
    }

    .footerTopimg {
        position: absolute;
        top: -150px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;
    }

    p {
        color: $whiteColor;
    }

    a {
        color: $whiteColor;
        text-decoration: none;
        font-family: 'Poppins-Regular';
        word-wrap: break-word;

        &:hover {
            color: $yellowColor;
            text-decoration: none;

            p {
                color: $yellowColor;
            }
        }
    }

    .rct-footer-div {
        padding: 70px 75px 30px;

        .footer-links {
            // padding-top: 40px;
            padding-top: 5px;

            p {
                font-size: 15px;
                text-transform: uppercase;
            }
        }

        .yellowColorBtn {
            color: $blackColor !important;

            &:hover {
                color: $whiteColor !important;
            }

            a {
                color: $blackColor !important;

                &:hover {
                    color: $whiteColor;
                }
            }
        }

        .footer-address-div {
            padding-right: 10px;

            p {
                margin-bottom: 0rem;
            }

            img {
                margin-bottom: 1rem;
                width: 180px;
            }

            .footer-social-links a {
                margin-right: 25px;

                &.fa::before {
                    font-size: 20px;
                }
            }
        }

        .subscribe-form {
            // padding-top: 40px;
            position: relative;

            &::before {
                display: none;
                z-index: 0;
                background-image: url('./../assets/footer-form.png');
                background-position: right;
                background-repeat: no-repeat;
                background-size: 35%;
                content: "";
                position: absolute;
                left: -60px;
                top: -20px;
                width: 100%;
                height: 35%;
                animation: blink 1.7s linear infinite;
            }

            h2 {
                color: $whiteColor;
                font-size: 30px;
                line-height: 45px;
                margin-bottom: 0;
                position: relative;
                width: 80%;

            }

            .side-image {
                position: absolute;
                top: -30px;
                right: -20px;

                img {
                    max-width: 35%;
                    float: right;
                    animation: blink 1.7s linear infinite;
                }
            }

            .subscribeForm {
                display: flex;
                width: 75%;
                border-radius: 50px;
                background-color: $whiteColor;
                margin: 2rem 0rem;

                .form-control {
                    height: calc(3rem + 2px);
                    border-radius: 0;

                    &:focus {
                        color: $redColor;
                        background-color: $whiteColor;
                        border-color: $whiteColor;
                        outline: 0;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $lightgrey;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: $lightgrey;
                    }

                    &::-ms-input-placeholder {
                        color: $lightgrey;
                    }
                }

                .btn {
                    height: auto;
                    margin: 3px;
                    border-radius: 0;
                }

                .subscribeText {
                    max-width: 70%;
                    width: 100%;
                    padding-left: 2rem;
                    font-weight: 600;
                    color: $redColor;
                    background-color: $whiteColor;
                    border: 2px solid $whiteColor;
                    float: left;
                    border-top-left-radius: 50px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 50px;
                }

                .subscribeButton {
                    max-width: 30%;
                    width: 100%;
                    background-color: $yellowColor;
                    border: 2px solid $whiteColor;
                    font-weight: bold;
                    color: $blackColor;
                    text-transform: uppercase;
                    cursor: pointer;
                    border-radius: 50px;
                    position: relative;
                    cursor: pointer;
                    transition: all .6s;
                    overflow: hidden;
                    z-index: 99;

                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background-color: $greenColor;
                        color: $whiteColor;
                        border: 2px solid $greenColor;
                        border-radius: 50px;
                        position: absolute;
                        transition: all .6s;
                        z-index: -1;
                        top: 0;
                        left: -100%;
                    }

                    &:hover {
                        background-color: $greenColor;
                        color: $whiteColor;
                        border: 2px solid $greenColor;
                        border-radius: 50px;

                        &:before {
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .rct-footer-copyright {
        border-top: 1px solid #d6d6d642;
        padding: 40px 85px;
        font-family: 'Poppins-Regular';

        p {
            margin-bottom: 0rem;
        }

        .rct-footer-right {
            position: relative;
            display: flex;
            justify-content: flex-end;

            .leftborder-links {
                position: relative;
                z-index: 1;

                &::after {
                    content: '|';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: -20px;
                    z-index: 1;
                    color: $whiteColor;
                }

                &:last-child::after {
                    display: none;
                }
            }

            .links {
                margin: 0px 20px;
            }
        }
    }
}

// Donate Button Fixed
.donate-button {
    position: fixed;
    left: auto;
    bottom: auto;
    z-index: 9999;
    width: auto;
    right: -50px;
    top: 50%;
    transform: rotate(-90deg);

    .donateBtn {
        display: inline-block !important;
        background-color: $greenColor !important;
        border: 1px solid $whiteColor !important;
        padding: 7px 20px 10px 20px !important;
        line-height: 2rem !important;
        color: $whiteColor !important;
        outline: none !important;
        text-align: center !important;
        text-transform: uppercase;
        font-family: 'Poppins-Regular';
        font-size: 15px !important;
        border-radius: 0px !important;
        letter-spacing: 1px;

        &:hover {
            color: $whiteColor;
            background-color: $blueColor !important;
        }

        svg {
            margin: 0px 10px 0px;
            -webkit-animation-name: heart;
            animation-name: heart;
            -webkit-animation-duration: 4s;
            animation-duration: 4s;
            -webkit-animation-delay: .3s;
            animation-delay: .3s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            -webkit-transition: all .3s;
            transition: all .3s;

        }
    }
}

// Donate Button Mobile View
.donate-button-mobile {
    width: 40px;
    height: 40px;
    padding: 10px;
    background-color: $greenColor;
    border: 1px solid $whiteColor;
    border-radius: 50%;
    position: fixed;
    bottom: 15px;
    right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;

    svg {
        color: $whiteColor;
        -webkit-animation-name: heart;
        animation-name: heart;
        -webkit-animation-duration: 4s;
        animation-duration: 4s;
        -webkit-animation-delay: .3s;
        animation-delay: .3s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transition: all .3s;
        transition: all .3s;

    }
}

//  Offcanvas Bootstrap Style
.modal-open {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    padding-right: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-width: 100%;
    visibility: hidden;
    background-color: $whiteColor;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.offcanvas-backdrop.fade {
    opacity: 0;
}

.offcanvas-backdrop.show {
    opacity: .5;
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.offcanvas-header .btn-close {
    padding: .5rem;
    margin-top: -.5rem;
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    box-sizing: content-box;
    cursor: pointer;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url('./../assets/closebtn.svg') 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
    position: absolute;
    top: 20px;
    right: 20px;
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5;

    h5 {
        font-size: 1.7rem;
        font-family: "Poppins-Bold";
        text-transform: uppercase;
    }

    p {
        font-size: 1rem;
    }
}

.offcanvas-body {
    flex-grow: 1;

    h4 {
        font-size: 1rem;
        font-family: "Poppins-Bold";
        text-transform: uppercase;
    }
}

.offcanvas-start {
    top: 0;
    right: 0;
    width: 500px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%);
    padding: 30px;
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 500px;
    border-left: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(100%);
}

.offcanvas-top {
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(-100%);
}

.offcanvas-bottom,
.offcanvas-top {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
}

.offcanvas-bottom {
    border-top: 1px solid rgba(0, 0, 0, .2);
    transform: translateY(100%);
}

.offcanvas.show {
    transform: none;
}

.radio-button-div {
    padding-left: 15px;

    .form-check-label {
        margin-right: 10px;
    }
}

.creditcard-info-div {
    i {
        margin-right: 10px;
    }
}

//  Banners Style Start


.bannerSec {
    // margin-top: 100px;
    // background-image: url('./../assets/home-banner.jpg');
    @include bg-cover;
    position: relative;
    width: 100%;
    color: $whiteColor;
    height: 750px;
    @include flex (column, space-between, center);
    background-blend-mode: saturation; 
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .bannerBottomimg {
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
    }

    .overlay {
        background-color: rgb(0 0 0 / 61%);
        opacity: 0.7;
        clear: float;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

    }

    // &.homebanner {
    //     .bannerHeight { 
    //         .bannerRow {
    //             .bannerDiv { 
    //                 h1 { 
    //                     line-height: 85px;
    //                     font-family: "Poppins-Bold";
    //                     font-style: normal;
    //                     font-size: 65px;
    //                     margin-bottom: 1rem; 
    //                 }
    //             }

    //         }
    //     }

    // }
    &.homebanner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*background-image: url('./../assets/home-banner.jpg');*/
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    .bannerHeight {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin: 0px 0px 100px 0px;
        .bannerRow {
            @include flex (row, center, center);

            .bannerDiv {

                // padding-top: 100px;
                h1 {
                    line-height: 85px;
                    font-family: "Poppins-Bold";
                    font-style: normal;
                    font-size: 65px;
                    margin-bottom: 1rem;
                }

                p {
                    padding: 0px 30px;
                    line-height: 35px;
                    font-size: 20px;
                }
            }
        }
    }

    &.news-events-banner { 
         background-image: url('./../assets/newseventsbanner.png');   
    }
    // &.aboutUsBanner {
    //     background-image: url('./../assets/aboutus-banner.jpg');
    //     filter: grayscale(100%);
    //     -webkit-filter: grayscale(100%);
    // }
    &.aboutUsBanner {

        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/aboutus-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.media-banner {

        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*  background-image: url('./../assets/media-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.media-inner-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/media-inner-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*  background-image: url('./../assets/rahul-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-inner-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/programs-inner-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.get-involved-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/female-hand-holds-heart.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.contact-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/contactus-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.privacy-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/privacy-policy-banner-img.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.terms-conditions-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/Terms-and-Conditions.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.stories-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/stories-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.stories-inner-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/selective-focus-of-poor-afrian.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.donateBanner {
        position: relative;
        height: 850px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-image: url('./../assets/donate-banner.jpg'); */
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-hope-project-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/programs-hope-project-banner.jpg');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-joy-project-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/programs-joy-project-banner.jpg');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-empower-project-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/programs-inner-empower-banner.jpg');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.programs-aspire-project-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/programs-inner-aspire-banner.jpg');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.muthuselvi-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/Muthuselvi.JPG');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.amuthavalli-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/amudha.JPG');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }

    &.lokeshwari-banner {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('./../assets/lokeshwari.jpg');
            @include bg-cover;
            filter: grayscale(100%);
        }
    }
}

// Home About Us Section
.aboutus-sec {
    padding: 50px 0px 100px;
    position: relative;
    width: 100%;
    height: 100%;
    @include flex (column, space-between, center);

    // max-width: 1300px;
    // padding: 50px 0px;
    // margin: 0 auto;
    .about-newbgimg-div {
        // background-image: url('./../assets/home_volounteer (1).jpg');
        // @include bg-cover;
        // position: relative;
        width: 100%;
        // height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
            top: 0;
        }

        video {
            width: 100%;
            height: 100%;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // min-width: 100%;
            // min-height: 100%;
            // width: auto;
            // height: auto;
            // z-index: 0;
            // -ms-transform: translateX(-50%) translateY(-50%);
            // -moz-transform: translateX(-50%) translateY(-50%);
            // -webkit-transform: translateX(-50%) translateY(-50%);
            // transform: translateX(-50%) translateY(-50%);
        }

        .about-div {
            padding: 100px;

            .countdiv {
                font-size: 100px;
            }
        }
    }

    .about-image-bg {
        background-image: url('./../assets/Impact-img.jpg');
        @include bg-cover;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .stories-change-div {
            padding: 75px;
            text-align: center;
            margin: 0 auto;
            z-index: 99;

            h3 {
                font-size: 45px;
                font-family: 'Poppins-Bold';
            }

        }

        .overlay {
            background-color: $blackColor;
            opacity: 0.75;
            clear: float;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .about-image-bg1 {
        background-image: url('./../assets/Impact.jpg');
        @include bg-cover;
        position: relative;
        width: 100%;
        //    height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .aboutHeadingDiv {
        @include flex-column-center;

        .aboutHeadingRow {
            @include flex (row, center, center);
            padding-bottom: 3rem;

            h1 {
                margin-bottom: 1.5rem;
            }

            p {
                padding: 0px 40px;
            }
        }
    }

    .aboutMiddleImage {
        position: relative;

        #topShadowDiv {
            fill: url(#topShadowDiv);
            width: 100%;
            max-width: 100%;
            height: 175px;
        }

        .topShadowDiv {
            height: 100%;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
        }

        #bottomShadowDiv {
            fill: url(#bottomShadowDiv);
            width: 100%;
            max-width: 100%;
            height: 100%;
        }

        .bottomShadowDiv {
            bottom: -1px;
            height: 850px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
        }
    }

    .aboutBottomDiv {
        // bottom: 50px;
        // padding: 0px 75px;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0;
        position: relative;
        // position: absolute;
        width: 100%;

        .lightBGcard {
            // background-color: $lightblue;
            padding: 30px;
            text-align: center;
            @include flex (column, center, center);

            h3 {
                font-size: 40px;
            }
        }

        .ratingsText {
            font-size: 7rem;
            margin-bottom: 0px;
        }

        .redBGcard {
            background-color: $greenColor;
            padding: 100px 100px;
            text-align: center;
            @include flex (column, center, center);
        }

        .map-image-bg {
            // background-image: url('./../assets/map-image1.png');
            background-color: #fbfbfb;
            background-repeat: no-repeat;
            // background-size: 80%;
            background-position: center;
            // background-position-y: 120px;
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .stories-change-div {
                padding: 30px;
                text-align: center;
                max-width: 500px;
                margin: 0 auto;

                h3 {
                    font-size: 45px;
                    font-family: 'Poppins-Bold';
                }
            }

            .circle-ripple-animation {
                margin: 0;
                position: absolute;
                top: 58%;
                left: 66%;
                transform: translate(-50%, -50%);

                .dot-div {
                    border-radius: 50%;
                    height: 15px;
                    width: 15px;
                    border: 1px solid $greenColor;
                    background-color: $greenColor;
                    z-index: 1;
                    position: relative;
                    animation: ripple 1s linear infinite;
                    cursor: pointer;

                    &:hover .location-info {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    .location-info {
                        opacity: 0;
                        transition: all 1s ease;
                        position: absolute;
                        left: -40px;
                        bottom: 30px;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        width: 100px;
                        background: #fff;
                        text-align: center;
                        padding: 5px 15px;
                        border-radius: 3px;
                        z-index: 1;
                        -webkit-box-shadow: 0 0 20px 20px rgb(0 0 0 / 5%);
                        box-shadow: 0 0 20px 20px rgb(0 0 0 / 5%);

                        &::before {
                            content: "";
                            position: absolute;
                            left: 35px;
                            bottom: -10px;
                            width: 25px;
                            height: 25px;
                            background: #ffffff;
                            z-index: -1;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            -webkit-box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 5%);
                            box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 5%);
                        }

                        h5 {
                            font-size: 15px;
                            margin-bottom: 0rem
                        }
                    }
                }
            }
        }
    }
}

// Count Value with css animation
@property --num {
    syntax: "<integer>";
    initial-value: 11328;
    inherits: false;
}

.countdiv {
    animation: counter 5s alternate ease-in-out;
    counter-reset: num var(--num);
}

.countdiv::after {
    content: counter(num);
}

@keyframes counter {
    from {
        --num: 11000;
    }

    to {
        --num: 11328;
    }
}

// HOME INTIATIVES SECTION
.commonHeadingDiv {
    @include flex-column-center;

    .commonHeadingRow {
        @include flex (row, center, center);

        p {
            padding: 0px 100px;
            margin-bottom: 1rem;
        }
    }
}

// programs Section Style
.programs-sec {
    position: relative;
    // margin-top: 150px;
    padding: 75px 0px;

    // padding: 50px 75px 200px 75px;
    .commonHeadingDiv {
        .commonHeadingRow {
            justify-content: left;
            padding: 0 90px;

            h1,
            p {
                padding: 0px 0px;
            }

        }
    }

    // &:after {
    //     z-index: 0;
    //     background-image: url('./../assets/causes_img_opt.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     right: 0;
    //     top: -150px;
    //     width: 45%;
    //     height: 110%;
    // }
    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/lightGeenimg.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: -150px;
    //     width: 100%;
    //     height: 40%;
    // }
    .OurProgramsSlidersDiv {
        padding: 0px 0px 0px 100px;

        .swiper {
            margin-top: -100px;
        }

        &.swiper-container {
            margin-top: 100px;
            position: relative;
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 25px;
        }

        .swiper-wrapper {
            margin-top: 100px;
        }

        .swiper-button-next {
            cursor: pointer;
            right: 230px;
            width: 50px;
            height: 50px;
            background-image: url('./../assets/2020_Properties_Arrows-28.svg');
            @include bg-cover;

            &:after {
                display: none;
            }
        }

        .swiper-button-prev {
            cursor: pointer;
            left: auto;
            right: 300px;
            width: 50px;
            height: 50px;
            background-image: url('./../assets/2020_Properties_Arrows-27.svg');
            @include bg-cover;

            &:after {
                display: none;
            }
        }
    }

    .programs-div {
        padding-top: 30px;

        .stories-content {
            margin: 20px 0px;
        }

        a {
            color: $blackColor;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

        }

        .programs-card {
            &:hover {
                box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);

            }

            background-color: $whiteColor;
            box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
            margin-bottom: 50px;
            position: relative;
            cursor: pointer;

            .card-top-stripe {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 1;
                background-color: $greenColor;
                color: $whiteColor;
                padding: 7px 15px;
                border-radius: 50px;
                font-size: 12px;
                font-family: 'Poppins-Regular';
            }

            .card-img {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                margin: 0 auto;
                border-radius: 0;

                img {
                    width: 100%;
                    transition: 1s all ease;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }

                }

            }

            .card-bg-content {
                position: relative;

                .card-top-stripe {
                    margin: 10px 0px 0px 95px;
                }

                .card-content {
                    margin-top: 30px;
                    padding: 50px 100px 40px 100px;
                }
            }

            .card-bg-img {
                position: relative;
                padding: 0;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .card-content {
                text-align: left;
                padding: 40px 40px;
                height: 310px;

                h5 {
                    font-family: 'Poppins-semi-Bold';
                    font-size: 17px;
                    line-height: 23px;
                }

                .progress {
                    background-color: rgba(214, 214, 214, 1);
                    border-radius: 50px;
                    margin: 10px 0px;
                    height: 15px;

                    .progress-bar {
                        background-color: $yellowColor;
                        -webkit-animation: progressBar 1500ms ease;
                        -webkit-animation-fill-mode: both;
                        -moz-animation: progressBar 1500ms ease;
                        -moz-animation-fill-mode: both;
                    }
                }
            }
        }
    }
}

.progress {
    background-color: rgba(214, 214, 214, 1);
    border-radius: 50px !important;
    margin: 10px 0px;
    height: 15px;

    .progress-bar {
        background-color: $yellowColor;
        -webkit-animation: progressBar 1500ms ease;
        -webkit-animation-fill-mode: both;
        -moz-animation: progressBar 1500ms ease;
        -moz-animation-fill-mode: both;
    }
}

@-webkit-keyframes progressBar {
    0% {
        width: 0;
    }
}

@keyframes progressBar {
    0% {
        width: 0;
    }
}

@-webkit-keyframes animOpacite {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes animOpacite {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}


// Latest News Section Style
.newslatestSlider {
    .swiper-wrapper {
        margin-top: 100px;
    }
}

.latestnews-sec {
    &.latest-news-inner {
        .commonHeadingDiv {
            .commonHeadingRow {
                justify-content: center;
            }
        }

        // margin-top: 70px;
        // margin-bottom: 70px; 
        .newsHeadingDiv {

            margin-bottom: -70px;
        }
    }

    a {
        color: $blackColor;
        text-decoration: none;

        &:hover {
            color: $blackColor;
            text-decoration: none;

        }

    }

    .newsHeadingDiv {
        padding-bottom: 2rem;

        .newsHeadingRow {
            .common-heading {
                text-align: left;
            }

            .buttonDIv {
                text-align: right;
            }
        }
    }

    position: relative;
    padding: 50px 75px 50px 75px;

    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/greenBG.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: -150px;
    //     width: 100%;
    //     height: 55%;
    // }
    .news-content {
        padding: 30px 0px;

        .newsDate {
            text-align: right;
        }

        h5 {
            margin-bottom: 1.5rem;

            &:hover {
                color: $greenColor;
            }
        }
    }

    .latest-news {
        .news-img {
            position: relative;
            width: 100%;
            height: 100%;

            &:after {
                z-index: 0;
                background-image: url('./../assets/news-hover.png');
                background-size: 100% auto;
                background-repeat: no-repeat;
                content: '';
                position: absolute;
                left: 0px;
                right: 0px;
                bottom: -10px;
                width: 100%;
                height: 50px;
                opacity: 0;
                transform: translateY(100%);
                transition: transform 0.36s cubic-bezier(0.39, 0.575, 0.28, 0.995);
            }

            img {
                transition: all 1s;
            }
        }

        &:hover {
            .news-img {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                margin: 0 auto;

                img {
                    width: 100%;
                    transition: 1s all;
                    -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                    transform: scale(1.05);
                }

                &:after {
                    opacity: 1;
                    transform: translateY(0);
                    transform: scale(1.05);
                }
            }

            h5 {
                color: $yellowColor;
            }
        }
    }

    &.latest-news-inner {

        // &:before {
        //     z-index: 0;
        //     background-image: url('./../assets/light-greenBG.png');
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     top: -150px;
        //     width: 100%;
        //     height: 55%;
        // }
        .latest-news {
            .news-img {
                position: relative;
                width: 100%;
                height: 100%;

                &:after {
                    z-index: 0;
                    background-image: url('./../assets/news-hover1.png');
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    content: '';
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: -10px;
                    width: 100%;
                    height: 50px;
                    opacity: 0;
                    transform: translateY(100%);
                    transition: transform 0.36s cubic-bezier(0.39, 0.575, 0.28, 0.995);
                }
            }

            &:hover {
                .news-img {
                    &:after {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}

// Get Involved Section Style 
.getinvolved-sec {
    position: relative;
    padding: 100px 0px 0px;

    .involved-sec {
        position: relative;
        padding: 50px 75px;
        width: 100%;

        #topShadowDiv {
            fill: url(#topShadowDiv);
        }

        .topShadowDiv {
            position: absolute;
            width: 100%;
            height: 326px;
            left: 0px;
            top: 0px;
        }

        &::before {
            // background-image: url('./../assets/getinvolved-banner.jpg');
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
            position: absolute;
            left: 0;
            top: 0px;
            width: 100%;
            z-index: 1;
            height: 100%;
        }

        .involved-div {
            position: relative;
            z-index: 1000;

            .involved-card {

                // margin-bottom: 100px;
                .involved-card-img {
                    position: relative;

                    .involved-card-content {
                        // position: absolute;
                        bottom: 0;
                        text-align: center;
                        width: 100%;
                        color: $blackColor;

                        // background: linear-gradient(to bottom, transparent 0%, black 100%); 
                        h6 {
                            // padding: 35px 10px;
                            padding: 5px 10px;
                            font-size: 20px;
                            // letter-spacing: 3px;
                            // font-weight: 600;
                            font-family: 'Poppins-semi-Bold';
                            text-transform: uppercase;
                        }
                    }
                }

                .involved-para-content {
                    text-align: center;
                    padding: 0px 0px 0px 30px;

                    a {
                        color: $greenColor;
                        text-decoration: none;
                        word-wrap: break-word;

                        &:hover {
                            color: $greenColor;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

    }
}

.home-donors-sec {
    position: relative;

    // margin-bottom: 150px;
    .donors-sec {
        padding: 75px 0px;

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.donorsSlider {

    .donors-card {
        display: flex;
        align-items: center;
        transition: 0.4s;

        .image-div {
            display: flex;
            position: relative;
            z-index: 1;
            align-items: center;

        }
    }

    .hover-image {
        display: none;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        visibility: hidden;
    }

    img {
        transition: 0.3s;
    }

    .hover-image {
        display: block;
        opacity: 0;
    }

    .image-div {
        &:hover {
            .hover-image {
                opacity: 1;
                visibility: visible;
                transform: translateY(0%);
            }

            .main-image {
                opacity: 0;
                transform: translateY(100%);
            }
        }
    }

    .hover-image {
        display: block;
        transform: translateY(-100%);
    }
}

// Media Page Style
.media-news-sec {
    &.latestnews-sec {
        &::before {
            display: none;
        }
    }

    .commonHeadingDiv {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .latest-news {
        margin-bottom: 20px;

        .news-img {
            &:after {
                background-image: url('./../assets/media-news-card.png');
            }
        }
    }
}

.news-top-circle {
    position: absolute;
    top: -40px;
    left: 60px;
    z-index: 3;

    .news-top-circle-div {
        height: 80px;
        width: 80px;
        border-radius: 50px;
        text-align: center;
        color: $whiteColor;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins-Bold';
        font-size: 9px;
        z-index: 10;

        &.yellowColorBG {
            color: $blackColor;
        }
    }
}

// Media Inner Page Style
.media-inner-description-sec.media-inner-description-sec1 {
    max-width: 1300px;
    margin: 0 auto;
    padding: 75px 0px 75px;
    position: relative;
    z-index: 9;
    .news-gal-title {
        text-align: center;
        padding: 10px 0px;
        font-weight: bold;
    }
}
.media-inner-description-sec {
    max-width: 950px;
    margin: 0 auto;
    padding: 75px 0px 75px;
    position: relative;
    z-index: 9;

    .media-inner-Row {
        border-bottom: 1px solid $lightgrey;

        .common-heading {
            font-size: 30px;
        }
    }

    .media-inner-des-smallhead {
        padding: 0rem 0rem 1rem;

        h6 {
            line-height: 35px;
            font-family: "Poppins-Bold";
            font-size: 20px;
            margin: 1rem 0rem;
        }
    }

    .media-inner-desc {
        .media-inner-desc-heading {
            font-family: "Poppins-Bold";
            font-size: 30px;
            margin-bottom: 1rem;
        }
    }
}

// Left Social Sharing Button sticy 
.social-sharing-buttons {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px -100px;
    float: left;

    .share-label {
        transform: rotate(-90deg);
        top: 115%;
        order: 2;
        margin: 0;
        padding-right: 20px;
        position: absolute;
    }

    a {
        position: relative;
        display: inline-block;
        text-decoration: none;
        margin: 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blackColor;

        &:hover {
            color: $blackColor;
            text-decoration: none;
        }
    }
}

// Programs Page Style
.programs-sec {
    &.programs-page-sec {
        padding: 75px 75px 75px 75px;
        margin-bottom: 0px;

        &:before {
            display: none;
        }

        .commonHeadingDiv {
            .commonHeadingRow {
                justify-content: center;
                padding: 0 100px;
            }
        }

    }
}

// Programs Inner page design
.programs-inner-div {
    img {
        padding-bottom: 2rem;
    }
}

.programs-inner-form-sec {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-top: -150px;
    z-index: 99;

    .programs-inner-form-card {
        background-color: $whiteColor;
        box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
        padding: 70px;
    }

    h4 {
        font-size: 30px;
        line-height: 3rem;
    }

    .program-price {
        margin-top: 10px;
        font-size: 22px;
    }

    input[type=radio] {
        display: none;

        &:checked+label {
            &:after {
                top: 5px;
                left: 5px;
                width: 15px;
                height: 15px;
                background-color: $greenColor;
                z-index: 0;
                border-radius: 2px;
                background-color: $greenColor;
                z-index: 1;
                border-radius: 50%;
            }
        }

        &:not(:checked)+label {
            &:after {
                width: 25px;
                height: 25px;
                border: 1px solid #000000;
                top: 0;
                border-radius: 50%;
                background-color: $whiteColor;
            }
        }
    }

    input[type=radio]+label {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            z-index: 1;
            width: 25px;
            height: 25px;
            border: 1px solid #000000;
            top: 0;
            border-radius: 50%;
            background-color: $whiteColor;
        }
    }

    input[type=radio]+label {
        position: relative;
        overflow: hidden;
        padding-left: 35px;
        padding-right: 20px;
        cursor: pointer;
        display: inline-block;
        line-height: 26px;
        font-family: "Poppins-Regular";

        &:after {
            content: '';
            position: absolute;
            left: 0;
            z-index: 1;
        }
    }

    .select-payment-method {
        h6 {
            font-size: 24px;
            position: relative;
            padding-bottom: 15px;
            margin: 20px 0px;
            border-bottom: 1px solid $blackColor;
            // width: 30%;
            display: inline-block;
            width: auto;
        }
    }

    .personal-info-div {
        h6 {
            font-size: 24px;
            position: relative;
            padding-bottom: 15px;
            margin: 20px 0px;
            border-bottom: 1px solid $blackColor;
            // width: 20%;
            display: inline-block;
            width: auto;
        }
    }

    .personal-info-form {
        .form-group {
            label {
                font-weight: bold;
            }
        }

        .form-control {
            height: calc(3rem + 2px);
            border-radius: 50px;
            font-size: 12px;
            margin-top: 7px;
        }
    }

    .donation-total-div {
        h6 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
}

// Get involved Page start
.become-an-agent-sec {
    margin: 0 auto;
    position: relative;
    max-width: 1230px;
    width: 100%;
    padding: 75px 0px 75px;

    .become-an-agent-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .become-an-agent-description {
        padding: 50px;
        width: 400px;

        h4 {
            font-family: "Poppins-Bold";
            margin-bottom: 20px;
        }
    }
}

.get-involved-inner-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.get-involved-header {
    .commonHeadingRow {
        padding: 65px 0px 40px;
        z-index: 9;
    }
}

.navtabactive {
    .nav-wrap {
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        z-index: 999;
    }

    .progress {
        position: fixed;
        max-width: 1240px;
        width: 100%;
        top: 75px;
    }
}

.nav-wrap {
    margin: 0 auto;
    padding: 0 60px;
    position: relative;
    max-width: 1240px;
    width: 100%;
    background-color: #f6f7f8;

    .wrp-inner {
        // border-bottom: 3px solid #e3e7eb;
        position: relative;
        height: 100%;
        padding-left: 60px;
        padding-right: 60px;
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav-item {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &::before {
                background-color: #e3e7eb;
                content: "";
                display: block;
                height: 8px;
                left: 0;
                position: absolute;
                top: 0;
                width: 3px;
            }

            a {
                text-decoration: none;
                color: $blackColor;
                opacity: 0.7;
                font-weight: bold;
                font-family: "Poppins-Bold";

                &:hover {
                    text-decoration: none;
                    color: $greenColor;
                }
            }

            .active {
                color: $greenColor
            }

            .number {
                margin-right: 10px;
            }
        }

    }
}

.get-involved-page-sec {
    margin: 0 auto;
    position: relative;
    max-width: 1230px;
    width: 100%;
    z-index: 9;

    .get-involved-image {
        padding-top: 30px;
    }

    &.career-form-sec {
        // padding-bottom: 150px;
    }

    .getinvolved-form {
        &.volunteers-form {
            padding: 75px 80px 75px;

        }

        &.fellowship-form {
            padding: 75px 80px 75px;
        }

        display: flex;
        flex-direction: column;
        padding: 75px 75px;

        h6 {
            font-size: 35px;
            margin-bottom: 50px;
        }

        label {
            font-weight: bold;
        }

        .form-control {
            height: calc(3rem + 2px);
            padding: 0.375rem 1.5rem;
            margin-bottom: 10px;
            border-radius: 50px;

            &:focus {
                box-shadow: none;
                border-color: $greenColor;
            }
        }

        textarea.form-control {
            height: auto;
            border-radius: 5px;
            resize: none;

            &:focus {
                box-shadow: none;
                border-color: $greenColor;
            }
        }

        button {
            margin: 0px 20px;
        }

        .yellowColorBtn {
            svg {
                position: relative;
                margin-top: -5px;
            }
        }
    }

}

.jobs-openings-sec {
    position: relative;
    padding: 0px 0px 50px;

    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/greenBG.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: 100%;
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: -100px;
    //     width: 100%;
    //     height: 40%;
    // }
    .jobssHeadingDiv {
        padding: 50px 0px;
    }

    .jobs-cards-div {
        margin: 0 auto;
        position: relative;
        max-width: 1230px;
        width: 100%;
    }

    .jobs-card {
        background-color: $whiteColor;
        box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
        padding: 70px;
        margin-bottom: 50px;
        max-width: 95%;
        width: 100%;

        h4 {
            font-family: "Poppins-Bold";
            margin-bottom: 20px;

        }
    }
}

// Get Involved FAQ sec
.faqSec {
    position: relative;
    // padding-bottom: 150px;
    padding: 75px 0px;

    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/light-greenBG.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: -100px;
    //     width: 100%;
    //     height: 50%;
    // }
    .container-fluid {
        margin: 0 auto;
        position: relative;
        max-width: 1230px;
        width: 100%;
    }

    .faqDescriptions {
        padding: 30px 0px;

        .accordion {
            .accordion-item {
                padding: 15px 40px 15px;
                background-color: #ffffff;
                margin-bottom: 40px;

                h2 {
                    font-size: 20px;
                    margin-bottom: 0px;
                }
            }

            .accordion-header {
                button {
                    border: none;
                    background: none;
                    text-align: left;
                    width: 100%;
                    margin: 0;
                    position: relative;
                    cursor: pointer;
                    padding: 20px 0 0;
                }

                .accordion-button {
                    position: relative;
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    width: 100%;

                    &::after {
                        // font-family: 'FontAwesome';
                        content: "";
                        float: right;
                        transition: all 0.5s;
                        margin-right: 10px;
                        margin-left: 10px;
                        background-image: url('./../assets/Arrows-01.svg');
                        @include bg-cover;
                        width: 13px;
                        height: 7px;
                    }

                    &.collapsed {
                        padding: 0px 0 0;

                        &::after {
                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            transform: rotate(180deg);
                            transition: all 0.5s;
                        }
                    }

                }
            }

            .accordion-collapse {
                p {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    margin-bottom: 0;
                    margin-top: 30px;
                    border-top: 1px solid $lightBlack;
                }

                &.collapse {
                    &.show {
                        // padding-bottom: 20px;
                    }
                }
            }
        }
    }

}

// Get Involved Partners Section 
.partners-section {
    background-color: #eef4ff;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 100px 0px 150px;

    .partners-row {
        display: flex;
        justify-content: center;
        align-items: center;

        .partners-description {
            padding-left: 100px;

            h4 {
                font-size: 35px;
                font-family: 'Poppins-Bold';
                line-height: 50px;
            }
        }
    }
}

// Get involved Page End

// Contact Us Page Start
.other-ways-contact-sec {
    position: relative;
    padding: 0px 0px 75px;
    margin-top: 0px;

    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/light-greenBG.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: -90px;
    //     width: 100%;
    //     height: 90%;
    // }
    .contactHeadingDiv {
        .common-heading {
            text-transform: none;
            margin: 50px 0px;
        }
    }

    .other-ways-contact-div {
        max-width: 1300px;
        margin: 0 auto;
        text-align: center;

        .circle-icon {
            .icon {
                width: 100px;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto 30px;
                background-color: $whiteColor;
                border-radius: 50px;

                svg {
                    width: 50px;
                    height: 50px;
                    fill: $greenColor;
                }
            }
        }

        .desc-div {
            width: 70%;
            margin: 0 auto;
        }

        a {
            color: $greenColor;
            text-decoration: none;
            word-wrap: break-word;

            &:hover {
                color: $yellowColor
            }
        }
    }
}

// Contact Us Page End

// Privacy Policy Page
.privacy-policy-sec {
    max-width: 1200px;
    margin: 0 auto;
    padding: 75px 0px 75px;

    .main-heading {
        font-size: 35px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 25px;
        margin: 20px 0px;
    }
}

// Stories Page Style Start

.stories-list-sec {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    a {
        text-decoration: none;
        outline: none;
        color: $blackColor;

        &:hover {
            text-decoration: none;
            outline: none;
            color: $blackColor;
        }
    }

    .stories-row-div {
        padding: 75px 0px 75px;
        // margin-bottom: 150px;
        z-index: 9;

        .commonHeadingRow {
            padding-bottom: 3rem;
        }

        .our-stories {
            margin-bottom: 50px;

            .stories-content {
                padding-top: 35px;

                .sub-heading {
                    font-size: 22px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .pagination {
        padding-top: 30px;
        padding-bottom: 40px;
    }
}

.stories-video-sec-heading {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    .stories-heading-div {
        padding: 50px 0px 50px;

        h4 {
            font-family: "Poppins-Bold";
            font-size: 40px;
            text-transform: uppercase;
        }
    }
}

.stories-video-sec {
    position: relative;
    color: $whiteColor;

    // margin-bottom: 100px;
    // &::after{
    //     content: "";
    //     z-index: 0;
    //     background-image: url('../assets/below-green.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     width: 100%;
    //     height: 350px;
    //     position: absolute;
    //     bottom: -100px;
    //     left: 0;
    // }
    .stories-video-container {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        z-index: 99;

        .stories-video-row {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 75px 0px;

            .stories-content {
                padding: 70px;
            }
        }
    }
}

.parallaxBG {
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /*background-image: url('./../assets/parallax-background.jpg');*/
    position: relative;
    width: 100%;
    @include flex (column, center, center);
    text-align: center;

    &.donate-parallax {
        /* background-image: url('./../assets/donate-parallax-bg.jpg'); */
        @include flex (column, center, center);
        text-align: left;
        color: $whiteColor;
        height: 650px;

        .donate-parallax-container {
            max-width: 1200px;
            margin: 0 auto;
            position: relative;

            .donate-parallax-content {
                padding-top: 20px;

                h5 {
                    font-size: 30px;
                    margin-bottom: 25px;
                }
            }
        }

    }

    .overlay {
        background-color: $blueColor;
        opacity: 0.7;
        clear: float;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .hero-text {
        position: relative;

        h2 {
            font-size: 5rem;
            color: $whiteColor;

        }

        p {
            position: relative;
            display: inline-block;

            &::after {
                content: "";
                position: absolute;
                bottom: -60%;
                right: 0%;
                background: url('./../assets/bottom-line-img.png') no-repeat center center / 100% 100%;
                width: 75%;
                height: 15px;
                z-index: 1;
            }
        }
    }
}

.home-parallax-sec {
    .parallaxBG {

        /* background-image: url('./../assets/Education-01.jpg'); */
        .hero-text {
            h2 {
                font-size: 60px;
                line-height: 80px;
                color: #ffffff;

                &::after {
                    background: url('./../assets/bottom-line-img.png') no-repeat center center / 50% 50%;
                    bottom: -25px;
                    right: 350px;
                }
            }

            p {
                color: $whiteColor;
                font-size: 23px;
                margin-top: 25px;
            }
        }

        .overlay {
            background-color: rgb(0 0 0 / 75%);
        }

    }
}

// Stories Inner page
.border-bottom-div {
    border-bottom: 1px solid $blackColor;
}

.stories-inner-description-sec {
    position: relative;
    // margin-bottom: 250px;
    z-index: 9;

    p {
        line-height: 30px;
    }

    .stories-desc-container {
        padding: 75px 0px 0px;
        max-width: 1100px;
        margin: 0 auto;

        .stories-border-row {

            .stories-row-div {
                max-width: 900px;
                padding: 0px 75px 75px;
                z-index: 9;

                .story-inner-Row {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-bottom: 0px;
                        font-weight: bold;
                        font-size: 17px;

                    }
                }

                .stories-inner-desc {
                    p {
                        line-height: 30px;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    .story-left-image {
        position: relative;
        padding: 75px 75px 75px 75px;

        &.donatedescription-container {

            // margin-bottom: 100px; 
            .stories-right-content {
                padding: 0px 0px 0px 70px;
            }
        }

        &.story-left-image1 {
            position: relative;
            padding: 0px 0px 0px 0px;

        }

        .stories-right-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .stories-left-image {
            position: relative;
            z-index: 10;

            &::after {
                content: "";
                position: absolute;
                bottom: -60px;
                right: -60px;
                background: url('./../assets/image-bottom1.png') no-repeat center center / 100% 100%;
                width: 200px;
                height: 200px;
                z-index: 1;
            }

            img {
                position: relative;
                z-index: 10;
            }

        }

        .stories-right-content {
            padding: 0px 70px;

            h4 {
                font-size: 1.5rem;
                margin-bottom: 20px;
                line-height: 30px;
            }
        }

        .stories-des-text {
            padding: 75px 65px 30px;
        }

        .stories-des-text1 {
            padding: 100px 65px;
            max-width: 775px;
            margin: 0 auto;
        }

        .stories-left-content {
            padding: 0px 100px 0px 0px;

            h4 {
                font-size: 1.5rem;
                margin-bottom: 20px;
                line-height: 30px;
            }
        }

        .stories-right-content1 {
            padding: 0px 60px 0px 60px;
        }

        .stories-highlight-text-div1 {
            padding: 0px 0px 0px 135px;
            position: relative;
            margin-left: 0px;
            margin-bottom: 0px;
            margin-top: 30px;

            ::before {
                position: absolute;
                content: "";
                left: 70px;
                top: -10px;
                bottom: -20px;
                background: url('./../assets/right-border-image1.png') no-repeat center center/100% 100%;
                width: 15px;
                height: auto;
                z-index: 1;
            }

            h3 {
                font-size: 1.75rem;
                line-height: 40px;
            }
        }

        .stories-highlight-text-div {
            padding: 0px 100px;
            position: relative;
            margin-left: 100px;
            margin-bottom: 100px;

            &::before {
                position: absolute;
                content: '';
                left: 0px;
                top: -30px;
                bottom: -50px;
                background: url('./../assets/right-border-image1.png') no-repeat center center / 100% 100%;
                width: 15px;
                height: 100%;
                z-index: 1;
            }

            h3 {
                font-size: 1.25rem;
                line-height: 40px;
            }
        }
    }
}


.smilarstories-sec {
    a {
        color: $blackColor;
        text-decoration: none;

        &:hover {
            color: $blackColor;
            text-decoration: none;
        }
    }

    position: relative;

    // padding-bottom: 150px;
    // &:before {
    //     z-index: 0;
    //     background-image: url('./../assets/light-greenBG.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: -150px;
    //     width: 100%;
    //     height: 55%;
    // }
    .stories-row-div {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        padding: 75px 0px 75px 0px;

        .our-stories {
            .stories-content {
                padding-top: 35px;

                .sub-heading {
                    font-size: 22px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

// Not Found Page 
.bannerSec.notfoundBanner {
    background-image: url('./../assets/404-banner.jpg');
    //  .bannerHeight {
    //     .bannerRow {
    //         .bannerDiv {
    //             h1{
    //             font-size: 10rem;
    //             margin-bottom: 5rem;
    //             }
    //             p{
    //                 font-size: 25px;
    //                 font-family: 'Poppins-Regular';
    //             }
    //         }
    //     }
    //  }
}

/* About Us page Styles */

// about us section
.about-us-about-section {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    padding: 100px 0px;

    // .common-heading{
    //     font-size: 20px;
    //     line-height: 30px;
    // }
    .about-image {
        cursor: pointer;
        position: relative;

        &:hover {
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
        }
    }
}

// our values section
.our-values {
    background-color: $greenColor;
    position: relative;
    padding: 50px 0px 50px;
    // margin-top: 100px;
    z-index: 1;

    // &::before{
    //     z-index: -1;
    //     content: "";
    //     background-image: url('../assets/greenBG.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     position: absolute;
    //     left: 0;
    //     top: -100px;
    //     width: 100%;
    //     height: 20%;
    // }
    .value-card-section {
        max-width: 1200px;
        position: relative;
        margin: 0 auto;
        padding: 30px 0px 0px;

        .value-img {
            position: relative;
            width: 100%;
            margin-bottom: 10px;

            img {
                width: 70px;
                height: 70px;
                position: absolute;
                top: -75px;
                background-color: $greenColor;
                // box-shadow: 0px 3px 24px rgb(0 0 0 / 10%);
                padding: 15px;
                border-radius: 50px;
                border: 1px solid $whiteColor;
            }
        }

        .value-card {
            background-color: $whiteColor;
            padding: 40px;
            @include flex (column, center, flex-start);
            margin-bottom: 50px;
            margin-right: 50px;

            p {
                margin-bottom: 0rem;
                line-height: 25px;
            }

            h2 {
                font-size: 25px;
            }
        }

    }
}

// aboutuspurple section
.about-us-purple-section {
    background-color: $whiteColor;
    position: relative;

    &.common-sec {
        padding: 75px 75px 75px 75px;
    }

    // &::before{
    //     z-index: 1;
    //     content: "";
    //     background-image: url('../assets/green-top-image.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     position: absolute;
    //     left: 0;
    //     top: -140px;
    //     width: 100%;
    //     height: 20%;
    // }
    .about-green-container {
        max-width: 1200px;
        position: relative;
        margin: 0 auto;
        padding-bottom: 70px;

        .about-green-content {
            padding: 0px 75px;
        }

        .image-container {
            position: relative;
            @include flex (column, center, center);

            .image-1 {
                position: absolute;
                bottom: -50px;
                left: -20px;
                width: 50%;
            }

            .image-2 {
                width: 85%;
                padding-left: 100px;
            }

        }
    }


}

// donation section
.donation-section {
    background-color: $lightGreen;
    position: relative;

    // padding: 5% 10% 5% 12%;
    // margin-bottom: 150px;
    // &::before{
    //     content: "";
    //     z-index: 0;
    //     background-color: $greenColor;
    //     width: 100%;
    //     height: 150px;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    // }
    // &::after{
    //     content: "";
    //     z-index: 0;
    //     background-image: url('../assets/below-light-green.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     width: 100%;
    //     height: 300px;
    //     position: absolute;
    //     bottom: -100px;
    //     left: 0;
    // }
    .about-donate-top-div {
        max-width: 1300px;
        margin: 0 auto;
        position: relative;
    }

    .donation-section-description {
        @include flex(column, center, flex-start);
        height: 100%;

        // padding-top: 25%;
        p {
            width: 80%;
        }
    }

    .donor-heart-image {
        @include flex(column, center, flex-start);
        height: 100%;

        img {
            width: 100%;
            position: relative;
            // top: -10px;
        }
    }

    .donation-count {
        @include flex(row, center, flex-start);
    }

    .donation-value {
        // max-width: 300px;
        // margin-right: 100px;
        z-index: 9;
    }

    .donation-number {
        font-size: 4.5rem;
        color: $blueColor;
    }

}

$breakpoints: (
    'sm': 630px,
    'md': 768px,
    'lg': 1024px,
);

// founder-story
.founder-story {
    position: relative;
    padding: 75px 0px;

    .founder-story-section {
        // padding: 50px 0px;
        position: sticky;
        top: 0;
    }

    // .founderSwipers {

    //     .swiper-slide {
    //         transition: 0.3s all ease;
    //         &.active {
    //             transition: 0.3s all ease;
    //         }
    //     }
    // }
    .scroll-line {
        position: relative;
        height: 300px;
        width: 1.5px;
        background-color: $greenColor;
        left: 0;
        right: 0;
        margin: 0px auto 0;
        transform: rotate(270deg);

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $yellowColor;
            -webkit-animation: scrolling-loop 3s ease-in-out infinite;
            animation: scrolling-loop 3s ease-in-out infinite;
        }

        &:before {
            -webkit-animation: scroll .35s ease-in infinite alternate;
            animation: scroll .35s ease-in infinite alternate;
            background-image: url('./../assets/arrow-img.svg');
            background-repeat: no-repeat;
            top: 200px;
            content: "";
            height: 25px;
            position: absolute;
            width: 25px;
            left: -10px;

        }
    }

    .founderSwipers {
        padding: 0;

        .swiper-slide {
            transition: 0.3s all ease;

            &.active {
                transition: 0.3s all ease;
            }
        }

        &.swiper-container {
            margin-top: 100px;
            position: relative;
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 0px;
        }

        .swiper-wrapper {
            margin-top: 100px;
        }

        .swiper-button-next {
            cursor: pointer;
            right: 100px;
            width: 50px;
            height: 50px;
            background-image: url('./../assets/2020_Properties_Arrows-28.svg');
            @include bg-cover;

            &:after {
                display: none;
            }
        }

        .swiper-button-prev {
            cursor: pointer;
            right: 170px;
            width: 50px;
            height: 50px;
            background-image: url('./../assets/2020_Properties_Arrows-27.svg');
            @include bg-cover;

            &:after {
                display: none;
            }
        }
    }

    .founder-story-description {
        height: 100%;
        @include flex(column, center, flex-start);
        padding-left: 200px;

        h2 {
            font-size: 25px;
            line-height: 40px;
        }

        p {
            // padding-left: 40px;
        }
    }

    .founder-story-description1 {
        height: 100%;
        @include flex(column, center, flex-start);
        padding-right: 30px;

        h2 {
            font-size: 18px;
            line-height: 40px;
        }

        p {
            padding-left: 0px;
        }

        img {
            margin-bottom: 20px;
        }
    }

    .founder-story-description2 {
        height: 100%;
        @include flex(column, center, flex-start);
        padding-right: 0px;

        h2 {
            font-size: 35px;
            padding-left: 100px;
            margin-bottom: 30px;
        }

        p {
            padding-left: 0px;
        }

        img {
            margin-bottom: 20px;
        }
    }

    .founder-story-description3 {
        height: 100%;
        @include flex(column, center, flex-start);
        padding: 0px 40px;
    }

    .founder-story-description5 {
        height: 100%;
        @include flex(column, center, flex-start);
        text-align: center;

        .founder-pic1 {
            position: absolute;
            left: -70px;
        }
    }

    .founder-story-description-final1 {
        padding-left: 30px;
    }

    .founder-story-description-final2 {
        .founder-pic {
            position: absolute;
            top: 110%;
            width: 75%;
            left: -15%;
        }
    }

    .founder-story-description-final3 {
        .common-heading {
            position: absolute;
            right: 100%;
            width: 100%;
            top: 50%;
        }

        p {
            position: absolute;
            width: 75%;
            top: 120%;
            right: 80%;
        }
    }

    .founder-pic {
        position: relative;

        img {
            transition: all 1400ms ease;
        }

        h2 {
            font-family: 'Poppins-Regular';
            font-size: 75px;
            color: $whiteColor;
            position: absolute;
            bottom: 0px;
            left: 40px;
        }
    }

    .founder-circle-row {
        @include flex(row, center, center);
        height: 100%;

        .founder-circle-div {
            position: relative;
            height: 100%;
            @include flex(column, center, center);

            .founder-circle {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: $greenColor;
                color: $whiteColor;
                @include flex(column, center, center);
                position: relative;
                z-index: 9;

                // &::after{
                //     position: absolute;
                //     content: '';
                //     width: 300px;
                //     height: 1px;
                //     background-color: $blackColor;
                //     left: 99px;      
                // } 
                span {
                    font-family: 'Poppins-Regular';
                    font-size: 12px;
                }
            }
        }
    }

}

// partners
.partners {
    &::before {
        content: "";
        background-color: #EEF4FF;
        position: absolute;
        left: 0;
        z-index: -1;
        top: -140px;
        width: 100%;
        height: 32%;
    }

    background-color: $lightblue;
    padding: 4% 5% 10% 5%;

    h2 {
        width: 80%;
    }
}

// annual reports
.annual-reports {
    background-color: $greenColor;
    position: relative;
    margin-bottom: 100px;

    &::before {
        z-index: 0;
        content: "";
        background-image: url('../assets/greenBG.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        top: -100px;
        width: 100%;
        height: 35%;
    }

    &::after {
        z-index: 0;
        content: "";
        background: $greenColor;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        bottom: -100px;
        width: 100%;
        height: 35%;
    }

    .annual-reports-container {

        // padding-bottom: 100px;
        .content-div {
            z-index: 99;
        }

        .card-div {
            z-index: 99;
        }
    }

    // .annual-reports-container .col-md-4,  .annual-reports-container .col-md-8{
    //     z-index: 99;
    // }
    .annual-report-download {
        background-color: $whiteColor;
        padding: 20px 30px;
        @include flex(row, space-between, center);
        margin-bottom: 10px;

        h3 {
            margin-bottom: 0rem;
            font-size: 20px;
            padding: 0px 20px;
        }

        .arrowIcon {
            border-left: 1px solid #000;
            color: $blackColor;
            height: 100%;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: $blackColor;
            }
        }
    }
}

// Donate Page design
.donateBanner {
    .donate-container {
        max-width: 1300px;
        margin: 0 auto;
        position: relative;

        // padding-top: 150px;
        .banner-card-col {
            position: relative;
            padding: 0px 0px;

            .banner-card {
                background: $whiteColor;
                color: $blackColor;
                border-radius: 15px;
                padding: 50px 30px;
                text-align: center;

                h6 {
                    color: $blueColor;
                    font-size: 30px;
                    margin-bottom: 10px;
                }

                p {
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }
    }

}

.donate-ratings-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;

    .donate-ratings-div {
        padding: 45px 0px 45px 0px;

        .donate-rating {

            // width: 80%;
            h5 {
                font-size: 75px;
                font-weight: bold;
                font-family: 'Poppins-Bold';
                color: $blueColor;
                margin-bottom: 10px;
            }

            P {
                font-size: 25px;
                line-height: 40px;
                font-weight: 600;
            }
        }
    }
}

.donate-banner-form-div {
    .form-control {
        border-radius: 50px !important;
        height: calc(3rem + 2px) !important;

        &:focus {
            box-shadow: none !important;
            border-color: $greenColor;
        }

    }

    .form-group.col-md-6 {
        padding-bottom: 10px;
    }
}


//  Donate Form 
.donate-form {
    .donateForm {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-family: 'Poppins-Bold';

        .give-currency-symbol {
            color: $whiteColor;
            background-color: $greenColor;
            border: none !important;
            height: 65px;
            width: 75px;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            border-radius: 50px 0px 0px 50px;
        }

        .donateText {
            color: $whiteColor;
            background-color: $greenColor;
            border: none !important;
            outline: none;
            text-align: right;
            height: 65px;
            font-size: 20px;
            border-radius: 0;
            border-radius: 0px 50px 50px 0px;

            &:focus {
                color: $whiteColor;
                background-color: $greenColor;
                border-color: transparent;
                outline: 0;
                box-shadow: none;
            }
        }

        ::placeholder {
            color: $whiteColor;
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: $whiteColor;
        }

        ::-ms-input-placeholder {
            color: $whiteColor;
        }
    }

    .amounts-list {
        display: flex;
        flex-wrap: wrap;
        clear: both;
        list-style: none;
        margin: -10px;
        padding-bottom: 30px;
        padding-top: 10px;
        font-family: 'Poppins-Bold';

        li {
            margin: 3px;

            button {
                padding: 10px 20px;
                border-radius: 50px;
                background-color: $lightGreen;
                color: $blueColor;

                &:hover {
                    background-color: $blueColor;
                    color: $greenColor;
                }
            }
        }
    }
}


.mobile-donate-button {
    display: none;
}

// Social Icons 
.social {
    position: fixed;
    top: 45%;
    right: 0;
    z-index: 999;

    ul {
        padding: 0px;

        li {
            display: block;
            margin: 5px 15px;
            text-align: left;
            padding: 10px 5px;
            background-color: $yellowColor;
            -webkit-border-radius: 30px 0px 0px 30px;
            -moz-border-radius: 30px 0px 0px 30px;
            border-radius: 30px 0px 0px 30px;
            -webkit-transform: translate(110px, 0);
            -moz-transform: translate(110px, 0);
            -ms-transform: translate(110px, 0);
            -o-transform: translate(110px, 0);
            transform: translate(110px, 0);
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;

            a {
                color: #000;
                text-decoration: none;

                &:hover {
                    color: #000;
                    text-decoration: none;
                }
            }

            &:hover {
                margin: 5px 0px;
                padding: 10px 5px;
                background-color: $yellowColor;
                -webkit-border-radius: 30px 0px 0px 30px;
                -moz-border-radius: 30px 0px 0px 30px;
                border-radius: 30px 0px 0px 30px;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                transition: all 1s;
                -webkit-transform: translate(0, 0);
                -moz-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                -o-transform: translate(0, 0);
                transform: translate(0, 0);

                i {
                    svg {
                        -webkit-transform: rotate(360deg);
                        -moz-transform: rotate(360deg);
                        -ms-transform: rotate(360deg);
                        -o-transform: rotate(360deg);
                        transform: rotate(360deg);
                        -webkit-transition: all 1s;
                        -moz-transition: all 1s;
                        -ms-transition: all 1s;
                        -o-transition: all 1s;
                        transition: all 1s;
                    }
                }
            }

            i {
                margin-left: 0px;
                margin-right: 10px;
                color: #000;
                background: #fff;
                padding: 5px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                font-size: 15px;
                background: #ffffff;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);

                svg {
                    padding-left: 2px;
                }
            }
        }
    }
}
// News and Events Page
.news-events-sec {
    &.programs-sec .programs-div .programs-card .card-content h5 {
       padding-top: 10px;
    }
    .news-event-name {
        font-size: 18px;
        color: #6c757d;
        text-transform: uppercase; 
    }
}

.news-inner-banner-bottom {
    .news-inner-banner-bottom-img {
        position: relative;
        img {
            position: relative;
            border-radius: 10px;  
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
        } 
    }
    .news-inner-banner-bottom-video {  
        position: relative;
        width: 100%; 
        height: 100%;  
        video {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
        }
    }
    .news-inner-banner-bottom-youtube {
        position: relative;
        iframe {
            width: 100%;
            height: 500px;
            border-radius: 10px;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
        }
    }
    .news-inner-banner-bottom-text {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $yellowColor;
        color: $whiteColor;
        padding: 17px 17px;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        z-index: 2;
        p{
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            font-size: 13px;
            font-weight: bold;
        }
    }
}
.news-inner-banner-bottom-pdf-container {
    // margin: 20px 0px;
    // padding: 30px 0px;
    // background-color: #f7f7f7;
    padding-bottom: 20px;
    .news-inner-banner-bottom-pdf-row {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .news-inner-banner-bottom-pdf {
        max-width: 950px;
        margin: 0 auto;
        position: relative;
    }
}
.prev-next-btn {
    a{
        color: $greenColor;
        text-decoration: none;
        &:hover {
            color: $greenColor;
            text-decoration: none;
        }
    }
}
.newsSlider  {
    .swiper-button-next, .swiper-button-prev {
        width: 50px;
        height: 50px;
        background-color: $yellowColor;
        border-radius: 50px;
    }
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: 'next';
        font-size: 20px;
        color: $whiteColor;
        font-weight: bold;
    }
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: 'prev';
        font-size: 20px;
        color: $whiteColor;
        font-weight: bold;
    }
}



/* Media Query Styles */
/* Media Query Responsiveness */

/* Menu Responsiveness */
@media only screen and (max-width: 992px) {
    .rahul-navbar {
        .mobile-donate-button {
            display: block;
            position: absolute;
            right: 60px;

            .yellowColorBtn {
                font-size: 12px !important;
                /* line-height: 20px !important; */
                margin-bottom: 0px !important;
                padding: 0px 10px 0px 10px !important;
                margin-top: 5px;
            }
        }

        &.navbar {
            padding: 0rem;
        }

        &.fixed-top {
            position: fixed;
        }

        &.bg-light {
            background-color: $whiteColor  !important;
            height: 60px;
        }

        &.navactive.bg-light {
            height: 60px;

            .navbar-brand {
                img {
                    width: 140px;
                }
            }


        }

        &.bg-light {
            .rahul-navBG {
                a {
                    color: $whiteColor;
                    font-size: 17px;
                    margin-bottom: 5px;

                    &:hover {
                        color: $yellowColor;
                    }
                }
            }

            .navbar-brand {
                .transparentLogo {
                    display: none;
                }

                .bgLogo {
                    display: block;
                }
            }
        }

        &.navactive.bg-light {
            .rahul-navBG {
                a {
                    color: $whiteColor;
                    font-size: 17px;
                    margin-bottom: 5px;

                    &:hover {
                        color: $yellowColor;
                    }
                }
            }

            .navbar-brand {
                .transparentLogo {
                    display: none;
                }

                .bgLogo {
                    display: block;
                }
            }
        }

        .navbar-brand {
            margin-right: 1rem;
            margin-left: 5px;
            // margin-top: -2px;

            img {
                width: 140px;
            }
        }

        .rahul-navBG.container {
            max-width: 100%;
            padding: 0;
            margin: 0;
        }

        .collapse {
            &.show {
                .nav-link {
                    &.yellowColorBtn {
                        display: none !important;
                    }
                }
            }
        }

        .rahul-navBG {
            a {
                color: $whiteColor;

                &:hover {
                    color: $yellowColor;
                }
            }
        }

    }

    .navbar-collapse.justify-content-end {
        justify-content: center !important;
    }

    .navbar-light .navbar-toggler {
        color: $yellowColor;
        border-color: $yellowColor;
    }

    .navbar-collapse {
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        left: 0px;
        top: 0px;
        right: 100%;
        background-color: $blueColor;
        z-index: 98;
        display: flex;
        flex-direction: column !important;
        justify-content: center !important;
        text-align: center !important;
        margin: 0;
        transition: all 500ms ease-in;
    }

    .navbar-collapse.collapsing {
        position: fixed !important;
    }

    .navbar-collapse.collapsing {
        left: -100%;
    }

    .navbar-collapse.show {
        left: 0px;
    }

    .navbar-toggler.collapsed~.navbar-collapse {
        transition: left 400ms ease-out;
    }

    .navbar-toggler {
        float: left;
        border: none;
        padding: 0px 15px 0px 0px;
    }

    .navbar-toggler:active,
    .navbar-toggler:focus {
        outline: none;
    }

    .navbar-toggler-icon {
        vertical-align: top;
    }

    .navbar-light .navbar-toggler-icon {
        width: 30px;
        height: 16px;
        z-index: 99;
        background-image: none;
        position: relative;
        border-bottom: 1px solid $blueColor;
        transition: all 300ms linear;
    }

    //  .navbar-light .navbar-toggler.collapsed .navbar-toggler-icon:after {
    //     width: 30px;
    // }
    // .navbar-light .navbar-toggler.collapsed .navbar-toggler-icon:before {
    //     width: 30px;
    // }
    .navbar-light .navbar-toggler-icon {
        background-image: none !important;
    }

    .navbar-light .navbar-toggler {
        border-color: $whiteColor  !important;
    }

    .navbar-light .navbar-toggler-icon:after,
    .navbar-light .navbar-toggler-icon:before {
        width: 30px;
        position: absolute;
        height: 1px;
        background-color: $blueColor;
        top: 0;
        left: 0;
        content: '';
        z-index: 2;
        transition: all 300ms linear;
    }

    .navbar-light .navbar-toggler-icon:after {
        top: 7px;
    }

    .navbar-toggler.collapsed .navbar-toggler-icon:after {
        transform: rotate(0deg);
        width: 20px;
        border-color: $blackColor;
        background-color: $blackColor;
    }

    .navbar-toggler.collapsed .navbar-toggler-icon:before {
        transform: translateY(0px) rotate(0deg);
        border-color: $blackColor;
        background-color: $blackColor;
    }

    .navbar-toggler.collapsed .navbar-toggler-icon {
        border-color: $blueColor;
    }

    .navbar-toggler .navbar-toggler-icon:after {
        transform: rotate(45deg);
        border-color: $whiteColor;
        background-color: $whiteColor;
    }

    .navbar-toggler .navbar-toggler-icon:before {
        transform: translateY(8px) rotate(-45deg);
        border-color: $whiteColor;
        background-color: $whiteColor;
    }

    .navbar-toggler .navbar-toggler-icon {
        border-color: transparent;
    }

    .navbar {
        height: auto;
        display: inherit;
    }

    .navbar-brand img {
        width: 70px;
    }

    .nav-link {
        padding: 1rem 1rem;

        &.yellowColorBtn {
            display: none;
        }
    }

}

@media only screen and (min-width: 320px) and (max-width: 767.5px) {
    .aboutus-sec .about-newbgimg-div img {
        position: relative;
    }
    .programs-sec .programs-div {
        padding-top: 15px;

        .stories-content {
            .sub-heading {
                line-height: 20px;
                font-size: 17px;
            }
        }


    }

    .social {
        display: none;
    }

    .home-donors-sec {
        margin-bottom: 70px;

        .donors-sec {
            padding: 0px 15px;
        }

    }

    // Donate Button
    .donate-button-mobile {
        opacity: 1;
    }

    .donate-button {
        display: none;
    }

    // Home Page Responsiveness
    // Common Fonts
    .common-heading {
        line-height: 30px;
        font-size: 25px;
    }

    .common-para {
        font-size: 15px;
        line-height: 23px;
    }

    .sub-heading {
        font-size: 20px;
        line-height: 20px;
    }

    // Common Heading
    .commonHeadingDiv {
        .commonHeadingRow {
            p {
                padding: 0px 0px;
            }
        }
    }

    // Common Btn
    .yellowColorBtn,
    .redColorBtn,
    .redBorderWhiteBtn {
        padding: 3px 15px 3px 15px !important;
        font-size: 12px !important;
    }

    // Banner Sec mobile responsiveness 
    .bannerSec {
        height: 400px;
        margin-top: 60px;
        .bannerHeight {
            justify-content: flex-end;
            margin: 0px 0px 30px 0px;
        }

        &.donateBanner {
            height: 700px;
        }

        .donate-banner-form-div .form-group.col-md-6 {
            padding-right: 15px;
        }

        video {
            height: 400px;
        }

        .bannerBottomimg {
            position: absolute;
            bottom: -20px;
        }

        .bannerHeight {
            .bannerRow {
                .bannerDiv {
                    padding-top: 0px;

                    h1 {
                        margin-bottom: 1rem;
                        font-size: 25px;
                        line-height: 30px;
                    }

                    p {
                        padding: 0px 0px;
                        line-height: 25px;
                    }
                }

            }
        }

    }

    .programs-sec {
        // margin-top: 50px;
        padding: 20px 15px 20px 15px;

        &:before {
            top: -40px;
            width: 100%;
            height: 5%;
        }

        .commonHeadingDiv {
            .commonHeadingRow {
                justify-content: left;
                padding: 0;

                h1,
                p {
                    padding: 0px 0px;
                }
            }
        }

        .OurProgramsSlidersDiv {
            padding: 0;

            .swiper {
                margin-top: 20px;
            }

            &.swiper-container {
                margin-top: 100px;
                position: relative;
            }

            .swiper-button-next,
            .swiper-button-prev {
                width: 70px;
                height: 70px;
                position: absolute;
                top: 25px;
            }

            .swiper-wrapper {
                margin-top: 100px;
            }

            .swiper-button-next {
                cursor: pointer;
                left: 75px;
                width: 50px;
                height: 50px;
                background-image: url('./../assets/2020_Properties_Arrows-28.svg');
                @include bg-cover;

                &:after {
                    display: none;
                }
            }

            .swiper-button-prev {
                cursor: pointer;
                left: 15px;
                width: 50px;
                height: 50px;
                background-image: url('./../assets/2020_Properties_Arrows-27.svg');
                @include bg-cover;

                &:after {
                    display: none;
                }
            }
        }

        .programs-div {
            .programs-card {
                margin-bottom: 30px;

                .card-bg-content {
                    position: relative;

                    .card-top-stripe {
                        margin: 0px 0px 0px 5px;
                    }

                    .card-content {
                        margin-top: 30px;
                        padding: 30px 10px 10px 10px;
                    }
                }

                .card-top-stripe {
                    padding: 5px 10px;
                    font-size: 12px;
                }

                .card-bg-img {
                    img {
                        position: relative;
                    }
                }

                .card-img {
                    position: relative;
                }

                .card-content {
                    padding: 20px 20px;
                    height: auto;

                    h5 {
                        font-size: 15px;
                    }

                    .progress {
                        margin: 10px 0px;
                    }
                }
            }
        }
    }

    .home-parallax-sec {
        .parallaxBG {
            /*  background-image: url('./../assets/Education-01.jpg'); */

            .hero-text {
                h2 {
                    font-size: 30px;
                    line-height: 40px;
                }
            }
        }
    }

    // Home About Us Section
    .aboutus-sec {
        padding: 20px 0px;
        position: relative;
        width: 100%;
        height: 100%;

        .about-image-bg {
            .stories-change-div {
                padding: 25px;

                h3 {
                    font-size: 25px;
                }
            }

        }

        .aboutHeadingDiv {

            // .common-heading{
            //     font-size: 20px;
            //     line-height: 30px;
            // }
            .aboutHeadingRow {
                padding-bottom: 2rem;
                margin-top: 0rem;

                h1 {
                    margin-bottom: 1rem;
                }

                p {
                    padding: 0px 0px;
                }
            }
        }

        .aboutMiddleImage {
            #topShadowDiv {
                width: 100%;
                max-width: 100%;
            }

            #bottomShadowDiv {
                width: 100%;
                max-width: 100%;
            }

            .bottomShadowDiv {
                height: auto;
            }
        }

        .aboutBottomDiv {
            bottom: 0px;
            padding: 0px 30px;
            position: relative;

            .padding-zero {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }

            .lightBGcard {
                padding: 10px;

                h3 {
                    font-size: 15px;
                }
            }

            .ratingsText {
                font-size: 30px;
                margin-bottom: 10px;
            }

            .redBGcard {
                padding: 10px 10px;
            }
        }
    }




    // Latest News Section Style
    .latestnews-sec {
        .newsHeadingDiv {
            padding-bottom: 1rem;

            .newsHeadingRow {
                .common-heading {
                    text-align: center;
                }

                .buttonDIv {
                    text-align: center;
                }
            }
        }

        position: relative;
        // margin-top: 50px;
        padding: 20px 15px 20px 15px;

        &:before {
            top: -95px;
            width: 100%;
            height: 10%;
        }

        .news-content {
            padding: 15px 0px;

            .newsDate {
                text-align: right;
            }

            h5 {
                margin-bottom: 1rem;
                font-size: 15px;
                line-height: 25px;
            }

            p {
                font-size: 15px;
                margin-bottom: 0rem;
            }
        }

        .latest-news {
            .news-img {
                &:after {
                    display: none;
                }
            }

            .newsDateRow {
                .col-md-6 {
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .text-uppercase {
                    text-align: left;
                }
            }
        }

        &.latest-news-inner {

            // padding-bottom: 60px;
            &:before {
                top: -60px;
            }
        }
    }


    // Get Involved Section Style 
    .getinvolved-sec {
        position: relative;
        padding: 20px 0px 0px;

        .involved-sec {
            padding: 15px 0px;

            #topShadowDiv {
                fill: url(#topShadowDiv);
            }

            .topShadowDiv {
                position: absolute;
                width: 100%;
                height: 326px;
                left: 0px;
                top: 0px;
            }

            &::before {
                background-size: 100%;
            }

            .involved-div {
                .involved-card {
                    margin-bottom: 0px;

                    .involved-card-img {
                        position: relative;
                        text-align: center;

                        .involved-card-content {
                            h6 {
                                padding: 10px 10px;
                                font-size: 16px;
                            }
                        }
                    }

                    .involved-para-content {
                        text-align: center;
                        padding: 0px 20px;
                    }
                }
            }

        }
    }

    // RCT Footer Style
    .rct-footer-sec {
        position: relative;

        .footerTopimg {
            top: -60px;
        }

        .rct-footer-div {
            padding: 20px 15px 20px;
            text-align: center;

            .footer-links {
                padding-top: 20px;

                p {
                    font-size: 15px;
                }
            }

            .footer-address-div {
                padding-right: 0px;

                p {
                    margin-bottom: 0rem;
                }

                img {
                    width: 20%;
                    margin-bottom: 1rem;
                }

                .footer-social-links a {
                    margin-right: 20px;
                }
            }

            .subscribe-form {
                padding-top: 0px;
                position: relative;

                &::before {
                    z-index: 0;
                    background-image: url('./../assets/footer-form.png');
                    background-position: right;
                    background-repeat: no-repeat;
                    background-size: 35%;
                    content: "";
                    position: absolute;
                    left: -60px;
                    top: -20px;
                    width: 100%;
                    height: 35%;
                }

                h2 {
                    font-size: 1rem;
                    line-height: 1.5;
                    width: 100%;
                    padding: 0px 30px;

                }

                .subscribeForm {
                    margin: 1rem 0rem;
                    width: 100%;

                    .form-control {
                        height: calc(2.5rem + 2px);
                        font-size: 12px;
                    }

                    .btn {
                        height: auto;
                        margin: 2px;
                        font-size: 10px;
                    }

                    .subscribeText {
                        padding-left: 1rem;
                    }
                }
            }
        }

        .rct-footer-copyright {
            border-top: 1px solid #d6d6d642;
            padding: 20px 15px;

            p {
                margin-bottom: 0rem;
            }

            .rct-footer-right {
                p {
                    margin: 0px 5px;

                }

                .leftborder-links {
                    &::after {
                        display: none;
                    }

                    &:last-child::after {
                        display: none;
                    }
                }
            }

            .text-left {
                text-align: center !important;
                justify-content: center;
            }

            .text-right {
                text-align: center !important;
                justify-content: center;
                display: flex;
                flex-direction: column;
            }
        }

    }

    // Media Page Style
    .media-news-sec {
        .commonHeadingDiv {
            margin-bottom: 10px;
        }

        .latest-news {
            margin-bottom: 10px;
        }
    }

    .news-top-circle {
        position: absolute;
        top: -20px;
        left: 30px;
        z-index: 3;

        .news-top-circle-div {
            height: 50px;
            width: 50px;
        }
    }

    // Latest News Section Style
    .newslatestSlider {
        .swiper-wrapper {
            margin-top: 80px;
        }
    }

    // Common Pagination style
    .pagination {
        padding-top: 0px;

        .page-item {
            .page-link {
                height: 40px;
                width: 40px;
            }
        }
    }

    // Media Inner Page Style
    .media-inner-description-sec {
        max-width: 100%;
        padding: 20px 10px;

        .media-inner-Row {
            .common-heading {
                line-height: 35px;
                font-size: 25px;
            }
        }

        .media-inner-des-smallhead {
            padding: 0rem 0rem 1rem;

            h6 {
                line-height: 25px;
                font-size: 17px;
                margin-bottom: 1rem;
            }
        }

        .media-inner-desc {
            .media-inner-desc-heading {
                font-size: 20px;
                margin-bottom: 1rem;
            }
        }
    }

    // Left Social Sharing Button sticy 
    .social-sharing-buttons {
        margin: 10px -35px;
        left: 15px;
    }

    // Programs Page Style
    .programs-sec {
        &.programs-page-sec {
            padding: 20px 15px 20px 15px;
            margin-top: 0px;
            margin-bottom: 0px;

            .commonHeadingDiv {
                .commonHeadingRow {
                    justify-content: center;
                    padding: 0 0px;
                }
            }

        }

    }

    // Programs Inner page design
    .programs-inner-div {
        img {
            padding-bottom: 1.5rem;
        }
    }

    .programs-inner-form-sec {
        max-width: 90%;
        margin-top: -70px;

        input[type=radio]+label {
            display: flex;
        }

        .programs-inner-form-card {
            padding: 10px;

            .pt-3 {
                padding-top: 0rem !important;
            }
        }

        h4 {
            font-size: 1.3rem;
            line-height: 2rem;
        }

        .program-price {
            margin-top: 0rem;
            font-size: 17px;
        }

        .select-payment-method {
            h6 {
                font-size: 17px;
                padding-bottom: 15px;
                margin-bottom: 15px;
                // width: 90%;
                display: inline-block;
                width: auto;
            }
        }

        .personal-info-div {
            h6 {
                font-size: 17px;
                padding-bottom: 15px;
                margin-bottom: 15px;
                // width: 50%;
                display: inline-block;
                width: auto;
            }
        }

        .donation-total-div {
            h6 {
                font-size: 20px;
                margin-bottom: 25px;
            }
        }
    }

    // Donate Page responsiveness

    // Donate Page design
    .donateBanner {
        .donate-container {
            .banner-card-col {
                position: relative;
                padding: 0px 0px;
                display: flex;
                justify-content: center;
                align-items: center;

                .banner-card {
                    padding: 15px 15px;

                    h6 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 15px;
                    }
                }
            }
        }

    }

    .donate-ratings-container {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;

        .donate-ratings-div {
            padding: 100px 0px 0px 0px;

            .donate-rating {

                // width: 80%;
                h5 {
                    font-size: 75px;
                    font-weight: bold;
                    font-family: 'Poppins-Bold';
                    color: $blueColor;
                    margin-bottom: 10px;
                }

                P {
                    font-size: 25px;
                    line-height: 40px;
                    font-weight: 600;
                }
            }
        }
    }

    //  Donate Form 
    .donate-form {
        .donateForm {
            .give-currency-symbol {
                height: 50px;
                font-size: 15px;
            }

            .donateText {
                height: 50px;
                font-size: 15px;
            }

        }

        .amounts-list {
            margin: -10px;
            padding-bottom: 30px;
            padding-top: 10px;

            li {
                margin: 3px;

                button {
                    padding: 5px 10px;
                    font-size: 12px;
                }
            }
        }
    }

    .parallaxBG {
        height: 450px;

        &.donate-parallax {
            height: auto;
            text-align: center;

            .donate-parallax-container {
                .donate-parallax-content {
                    padding-top: 20px;

                    h5 {
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                }
            }

        }

        .hero-text {
            h2 {
                font-size: 35px;

            }
        }
    }

    // Stories Inner page 
    .stories-inner-description-sec {

        // margin-bottom: 50px;
        p {
            line-height: 23px;
        }

        .stories-desc-container {
            padding: 15px 15px 0px 60px;

            .stories-border-row {

                .stories-row-div {
                    padding: 0px 15px 15px;

                    .story-inner-Row {
                        p {
                            font-size: 15px;

                        }
                    }

                    .stories-inner-desc {
                        p {
                            line-height: 30px;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        .story-left-image {
            position: relative;
            padding: 15px 15px 0px 15px;

            &.donatedescription-container {
                margin-bottom: 10px;

                .stories-right-content {
                    padding: 30px 0px 0px 0px;
                    text-align: center;
                }
            }

            &.story-left-image1 {
                padding: 20px 0px 20px 0px;
                text-align: center;

            }

            .stories-left-image {
                &::after {
                    bottom: -30px;
                    right: -20px;
                    width: 100px;
                    height: 100px;
                }

            }

            .stories-right-content {
                padding: 30px 15px 0px;
                text-align: center;

                h4 {
                    font-size: 25px;
                    margin-bottom: 15px;
                    line-height: 30px;
                }
            }

            .stories-des-text {
                padding: 0px 15px;
                text-align: center;
            }

            .stories-des-text1 {
                padding: 5px 15px;
                text-align: center;
            }

            .stories-left-content {
                padding: 0px 10px 0px 0px;

                h4 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    line-height: 30px;
                }
            }

            .stories-right-content1 {
                padding: 0px 15px 0px 15px;
            }

            .stories-highlight-text-div1 {
                padding: 0px 0px 0px 15px;
                margin-top: 30px;

                h3 {
                    font-size: 25px;
                    line-height: 25px;
                }
            }

            .stories-highlight-text-div {
                padding: 0px 25px;
                margin-left: 15px;
                margin-bottom: 15px;

                h3 {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
        }
    }


    .smilarstories-sec {
        padding: 15px 15px 15px 15px;
        text-align: center;

        &:before {
            top: -60px;
        }

        .stories-row-div {
            max-width: 1200px;
            margin: 0 auto;
            padding: 15px 0px 15px 0px;

            .our-stories {
                .stories-content {
                    padding-top: 35px;

                    .sub-heading {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    // stories page
    .stories-list-sec {
        text-align: center;

        .stories-row-div {
            padding: 15px 15px 15px;
            // margin-bottom: 30px;
            z-index: 9;

            .commonHeadingRow {
                padding-bottom: 1rem;
            }

            .our-stories {
                margin-bottom: 20px;

                .stories-content {
                    padding-top: 15px;

                    .sub-heading {
                        font-size: 17px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .stories-video-sec-heading {
        .stories-heading-div {
            padding: 20px 15px 20px;
            text-align: center;

            h4 {
                font-size: 25px;
            }
        }
    }

    .stories-video-sec {

        // margin-bottom: 100px;
        // &::after{
        //     content: "";
        //     z-index: 0;
        //     background-image: url('../assets/below-green.png');
        //     background-repeat: no-repeat;
        //     background-size: cover;
        //     width: 100%;
        //     height: 350px;
        //     position: absolute;
        //     bottom: -100px;
        //     left: 0;
        // }
        .stories-video-container {
            .stories-video-row {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 0px;

                .stories-content {
                    padding: 20px;
                    text-align: center;
                }
            }
        }
    }

    .padding-zero {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    // Get involved Page start
    .become-an-agent-sec {
        max-width: 100%;
        width: 100%;
        padding: 15px 0px 15px;

        .become-an-agent-description {
            padding: 10px;
            width: 100%;

            h4 {
                font-family: "Poppins-Bold";
                margin-bottom: 20px;
            }
        }
    }

    .get-involved-header {
        .commonHeadingRow {
            padding: 15px 0px 10px;
        }
    }

    .navtabactive {
        .nav-wrap {
            top: 65px;
        }

        .progress {
            top: 60px;
        }
    }

    .get-involved-image {
        &.col-md-10 {
            padding: 0;
        }
    }

    .nav-wrap {
        padding: 0 10px;

        .wrp-inner {
            padding-left: 0px;
            padding-right: 0px;
        }

        .nav {
            .nav-item {
                height: 40px;

                a {
                    font-size: 9px;
                }

                .number {
                    margin-right: 5px;
                }
            }

        }
    }

    .get-involved-page-sec {
        max-width: 100%;
        width: 100%;

        .get-involved-image {
            padding-top: 30px;
        }

        &.career-form-sec {
            // padding-bottom: 70px;
        }

        .getinvolved-form {
            &.volunteers-form {
                padding: 15px 15px 15px;
            }

            &.fellowship-form {
                padding: 15px 15px 15px;
            }

            display: flex;
            flex-direction: column;
            padding: 15px 15px;

            h6 {
                font-size: 25px;
                margin-bottom: 20px;
            }

            label {
                font-weight: bold;
            }

            .form-control {
                height: calc(3rem + 2px);
                padding: 0.375rem 1.5rem;
                margin-bottom: 10px;
                border-radius: 50px;
            }

            button {
                margin: 5px 20px;
            }

            .yellowColorBtn {
                svg {
                    position: relative;
                    margin-top: -5px;
                }
            }
        }

    }

    .jobs-openings-sec {
        padding: 0px 0px 10px;

        &:before {
            z-index: 0;
            background-image: url('./../assets/greenBG.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            content: "";
            position: absolute;
            left: 0;
            top: -100px;
            width: 100%;
            height: 40%;
        }

        .jobssHeadingDiv {
            padding: 50px 0px;
        }

        .jobs-card {
            padding: 20px;
            margin-bottom: 20px;
            max-width: 100%;

            h4 {
                margin-bottom: 15px;
                font-size: 20px;
            }
        }
    }

    // Get Involved FAQ sec
    .faqSec {
        position: relative;
        // padding-bottom: 80px;
        padding: 15px 0px;

        &:before {
            top: -80px;
            height: 35%;
        }

        .faqDescriptions {
            padding: 10px 0px;

            .accordion {
                .accordion-item {
                    padding: 10px 10px 10px;
                    margin-bottom: 15px;

                    h2 {
                        font-size: 15px;
                        margin-bottom: 0px;
                    }
                }

                .accordion-header {
                    button {
                        border: none;
                        background: none;
                        text-align: left;
                        width: 100%;
                        margin: 0;
                        position: relative;
                        cursor: pointer;
                        padding: 10px 0 0;
                    }
                }

                .accordion-collapse {
                    p {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-bottom: 0;
                        margin-top: 15px;
                    }
                }
            }
        }

    }

    // Get Involved Partners Section 
    .partners-section {
        background-color: #eef4ff;
        background-size: cover;
        width: 100%;
        height: 100%;
        padding: 100px 0px 150px;

        .partners-row {
            display: flex;
            justify-content: center;
            align-items: center;

            .partners-description {
                padding-left: 100px;

                h4 {
                    font-size: 35px;
                    font-family: 'Poppins-Bold';
                    line-height: 50px;
                }
            }
        }
    }

    // Get involved Page End






}

@media only screen and (min-width: 320px) and (max-width: 576px) {}

@media only screen and (min-width: 320px) and (max-width: 450px) {

    // added  
    .home-parallax-sec {
        .parallaxBG {
            .hero-text {
                h2 {
                    font-size: 30px;
                    line-height: 40px;

                }
            }
        }
    }



    // End of about bottom section mobile responsiveness 

}

@media only screen and (min-width: 450px) and (max-width: 767.5px) {


    .home-parallax-sec {
        .parallaxBG {

            // added 
            .hero-text {
                h2 {
                    font-size: 30px;
                    line-height: 40px;

                }
            }
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .rahul-navbar {
        .mobile-donate-button {
            display: block;
            position: absolute;
            right: 8%;

            .yellowCol1orBtn {
                // margin-left: 120px;
                margin-left: 0;
            }
        }

        &.navactive.bg-light {
            // background-color: #ffffff !important;
            // box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
            // transition: all 0.5s ease;
            // .navbar-brand { 
            //     img {
            //         width: 145px;
            //         transition: all 0.5s ease;
            //     }
            // }
        }

        .navbar-brand {
            // background-color: $blueColor;
            // padding: 20px 10px;
            // margin-right: 0rem;
            // img {
            //     width: 140px;
            //     transition: all 0.5s ease;
            // }
        }
    }


    .aboutus-sec .about-image-bg .stories-change-div {
        h3 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }

        padding: 15px;
    }


    .parallaxBG {
        height: 500px;

        &.donate-parallax {
            height: auto;

            .donate-parallax-container {
                .donate-parallax-content {
                    padding-top: 20px;
                    text-align: center;

                    h5 {
                        font-size: 30px;
                        margin-bottom: 25px;
                    }
                }
            }

        }

        .hero-text {
            h2 {
                font-size: 30px;
                line-height: 40px;

            }
        }
    }

    .navtabactive {
        .nav-wrap {
            position: fixed;
            top: 65px;
            left: 0;
            right: 0;
            z-index: 999;
        }

        .progress {
            max-width: 100%;
            width: 100%;
            top: 60px;
        }
    }

}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .rahul-navbar {
        &.navbar {
            padding: 10px;
        }

        &.fixed-top {
            // position: absolute;
        }

        &.bg-light {
            height: 100px;
        }

        &.navactive.bg-light {
            height: 75px;

            .navbar-brand {
                img {
                    width: 125px;
                }
            }
        }

        .navbar-brand {

            // background-color: $blueColor;
            // padding: 20px 10px;
            // margin-right: 0rem;
            img {
                width: 100px;
                transition: all 0.5s ease;
            }
        }

        .navbar-brand {

            // background-color: $blueColor;
            // padding: 20px 10px;
            // margin-right: 0rem;
            img {
                width: 150px;
                transition: all 0.5s ease;
            }
        }

        .nav-link {
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 1px;
            font-family: 'Poppins-Regular';
            position: relative;
            text-transform: uppercase;
            padding: 10px 10px;

            &.yellowColorBtn {
                margin-left: 0px;
                font-size: 11px !important;
            }
        }

    }

    .parallaxBG {
        height: 500px;

        &.donate-parallax {
            height: auto;

            .donate-parallax-container {
                .donate-parallax-content {
                    padding-top: 20px;

                    h5 {
                        font-size: 30px;
                        margin-bottom: 25px;
                    }
                }
            }

        }

        .hero-text {
            h2 {
                font-size: 60px;
                line-height: 80px;

            }
        }
    }

    .navtabactive {
        .nav-wrap {
            position: fixed;
            top: 85px;
            left: 0;
            right: 0;
            z-index: 999;
        }

        .progress {
            max-width: 100%;
            width: 100%;
            top: 80px;
        }
    }
}



@media only screen and (min-width: 768px) and (max-width: 1023.5px) {

    .about-image-bg {

        .stories-change-div {
            padding: 30px;

            h3 {
                font-size: 30px;
            }

        }
    }

    // Home Page Responsiveness
    // Common Heading style
    .common-heading {
        line-height: 50px;
        font-size: 30px;
    }

    .common-para {
        font-size: 15px;
        line-height: 25px;
        font-family: 'Poppins-Regular';
    }

    .sub-heading {
        font-size: 20px;
        line-height: 25px;
    }

    // Common Heading
    .commonHeadingDiv {
        .commonHeadingRow {
            p {
                padding: 0px 0px;
            }
        }
    }

    // Home About Us Section
    .aboutus-sec {
        padding: 50px 0px;

        .aboutHeadingDiv {
            .aboutHeadingRow {
                @include flex (row, center, center);
                padding-bottom: 2rem;

                h1 {
                    line-height: 60px;
                    font-size: 40px;
                    margin-bottom: 1rem;
                }

                p {
                    padding: 0px 50px;
                }
            }
        }

        .aboutBottomDiv {
            padding: 0px 50px;

            .lightBGcard {
                padding: 30px;

                h3 {
                    font-size: 30px;
                }
            }

            .ratingsText {
                font-size: 80px;
                margin-bottom: 20px;
            }

            .redBGcard {
                padding: 40px 40px;
            }
        }

        // added  
        .aboutMiddleImage {
            position: relative;
            height: 850px;
        }
    }

    // programs Section Style
    .programs-sec {
        position: relative;
        // margin-top: 100px;
        padding: 50px 50px 50px 50px;

        &:before {
            top: -100px;
            height: 20%;
        }

        .programs-div {

            .programs-card {
                margin-bottom: 30px;

                .card-bg-content {
                    .card-top-stripe {
                        margin: 10px 0px 0px 25px;
                        font-size: 10px;
                        top: 7px;
                        left: 7px;
                    }
                }


                .card-content {
                    padding: 15px 15px;

                    h5 {
                        font-size: 15px;
                        line-height: 20px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 20px;
                    }

                    .progress {
                        margin: 15px 0px;
                    }
                }

                .card-bg-content {
                    .card-content {
                        padding: 15px 15px;
                        margin-top: 40px;

                        h5 {
                            font-size: 15px;
                            line-height: 20px;
                        }

                        p {
                            font-size: 15px;
                            line-height: 20px;
                        }

                        .progress {
                            margin: 15px 0px;
                        }
                    }
                }
            }
        }
    }

    // Latest News Section Style
    .latestnews-sec {
        .newsHeadingDiv {
            padding-bottom: 1.5rem;
        }

        margin-top: 0px;
        padding: 50px 35px 50px 35px;

        .news-content {
            padding: 20px 0px;

            .newsDate {
                text-align: right;
            }

            h5 {
                margin-bottom: 1rem;
                font-size: 15px;
                line-height: 25px;
            }

            p {
                font-size: 15px;
                margin-bottom: 0rem;
            }
        }

        .latest-news {
            .news-img {
                position: relative;
                width: 100%;
                height: 100%;

                &:after {
                    width: 100%;
                    height: 50px;
                    bottom: -25%;
                }
            }

            &:hover {
                .news-img {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        &.latest-news-inner {

            // padding-bottom: 150px;
            &:before {
                top: -60px;
            }

            .latest-news {
                .news-img {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    &:after {
                        width: 100%;
                        height: 50px;
                        bottom: -25%;
                    }
                }

                &:hover {
                    .news-img {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    // Get Involved Section Style 
    .getinvolved-sec {
        position: relative;
        padding: 50px 0px 0px;

        .involved-sec {
            position: relative;
            padding: 50px 50px;
            width: 100%;

            .involved-div {
                .involved-card {
                    .involved-card-img {
                        position: relative;

                        .involved-card-content {
                            h6 {
                                padding: 10px 10px 0px;
                                font-size: 15px;
                            }
                        }
                    }

                    .involved-para-content {
                        text-align: center;
                        padding: 10px;
                    }
                }
            }

        }
    }

    // RCT Footer Style
    .rct-footer-sec {
        .footerTopimg {
            top: -90px;
        }

        .rct-footer-div {
            padding: 30px 20px 30px;

            .col-md-2 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .footer-links {
                padding-top: 40px;

                p {
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .footer-address-div {
                padding-right: 0px;

                p {
                    margin-bottom: 0rem;
                }

                img {
                    margin-bottom: 1rem;
                }

                .footer-social-links a {
                    margin-right: 20px;

                    &.fa::before {
                        font-size: 20px;
                    }
                }
            }

            .subscribe-form {
                padding-top: 30px;
                position: relative;

                &::before {
                    z-index: 0;
                    background-image: url('./../assets/footer-form.png');
                    background-position: right;
                    background-repeat: no-repeat;
                    background-size: 35%;
                    content: "";
                    position: absolute;
                    left: -60px;
                    top: -20px;
                    width: 100%;
                    height: 35%;
                }

                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    width: 90%;

                }

                .subscribeForm {
                    margin: 1rem 0rem;
                    width: 85%;

                    .form-control {
                        height: calc(2.5rem + 2px);
                        font-size: 12px;
                    }

                    .btn {
                        height: auto;
                        margin: 2px;
                        font-size: 10px;
                    }

                    .subscribeText {
                        padding-left: 1rem;
                    }
                }
            }
        }

        .rct-footer-copyright {
            padding: 30px 20px;

            .col-md-6 {
                &.text-left {
                    flex: 0 0 30%;
                    max-width: 30%;
                }
            }

            p {
                margin-bottom: 0rem;
            }

            .col-md-6 {
                &.text-right {
                    flex: 0 0 70%;
                    max-width: 70%;
                    padding: 0;
                }

                &.rct-footer-right {
                    p {
                        margin: 0px 5px;

                        &::after {
                            right: -10px;
                        }
                    }

                    .links {
                        margin: 0px 10px;
                    }
                }
            }
        }
    }

    // Media Page Style
    .media-news-sec {
        &.latestnews-sec {
            padding: 0px 50px 150px 50px;
        }

        .commonHeadingDiv {
            margin-bottom: 10px;
        }

        .latest-news {
            margin-bottom: 15px;
        }

    }

    .news-top-circle {
        position: absolute;
        top: -25px;
        left: 30px;
        z-index: 3;

        .news-top-circle-div {
            height: 50px;
            width: 50px;
        }
    }

    // Latest News Section Style
    .newslatestSlider {
        .swiper-wrapper {
            margin-top: 80px;
        }
    }

    // Media Inner Page Style
    .media-inner-description-sec {
        max-width: 100%;
        padding: 40px 40px;

        .media-inner-Row {
            .common-heading {
                line-height: 35px;
                font-size: 30px;
            }
        }

        .media-inner-des-smallhead {
            padding: 1.8rem 0rem 1rem;

            h6 {
                line-height: 30px;
                font-size: 20px;
                margin-bottom: 1rem;
            }
        }

        .media-inner-desc {
            .media-inner-desc-heading {
                font-size: 23px;
                margin-bottom: 1rem;
            }
        }
    }

    // Left Social Sharing Button sticy 
    .social-sharing-buttons {
        margin: 10px -40px;
        left: 15px;
    }

    .social {
        ul {
            li {


                i {
                    svg {
                        padding-left: 0px;
                    }
                }
            }
        }
    }

    // Programs Page Style
    .programs-sec {
        &.programs-page-sec {
            padding: 50px 40px 50px 40px;
            margin-top: 0px;

            .commonHeadingDiv {
                .commonHeadingRow {
                    justify-content: center;
                    padding: 0 20px;
                }
            }

        }
    }

    // Programs Inner page design
    .programs-inner-div {
        img {
            padding-bottom: 1.5rem;
        }
    }

    .programs-inner-form-sec {
        max-width: 90%;
        margin-top: -70px;

        .programs-inner-form-card {
            padding: 30px;
        }

        h4 {
            font-size: 1.3rem;
            line-height: 2rem;
        }

        .program-price {
            margin-top: 1rem;
            font-size: 19px;
        }

        .select-payment-method {
            h6 {
                font-size: 17px;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }

        .personal-info-div {
            h6 {
                font-size: 17px;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }

        .donation-total-div {
            h6 {
                font-size: 20px;
                margin-bottom: 25px;
            }
        }
    }

    .nav-wrap {
        margin: 0 auto;
        padding: 0 0px;

        .wrp-inner {
            padding-left: 20px;
            padding-right: 20px;
        }

        .nav {
            .nav-item {
                height: 80px;

            }

        }
    }

    .bannerSec {
        .bannerHeight { 
                margin: 0px 0px 60px 0px;
            .bannerRow {
                .bannerDiv {
                    h1 {
                        line-height: 55px;
                        font-size: 50px;
                        margin-bottom: 1rem;
                    }

                    &.col-md-7 {
                        flex: 0 0 90%;
                        max-width: 90%;
                    }
                }

            }
        }

    }


    .home-parallax-sec {
        .parallaxBG {
            .hero-text {
                h2 {
                    font-size: 40px;
                    line-height: 60px;
                    color: #ffffff;
                }

                &::after {
                    background: url('./../assets/bottom-line-img.png') no-repeat center center / 100% 50%;
                    bottom: -25px;
                    right: 200px;
                }
            }
        }
    }

    .programs-sec {
        .commonHeadingDiv {
            .commonHeadingRow {
                padding: 0;
            }
        }

        .OurProgramsSlidersDiv {
            padding: 0px;
            margin-top: 2rem;

            .swiper-button-next {
                top: 25px;
                right: 0px;
            }

            .swiper-button-prev {
                top: 25px;
                right: 60px;
            }
        }
    }

    // Stories Page Style Start

    .stories-list-sec {
        .stories-row-div {
            padding: 20px 50px 20px;

            // margin-bottom: 80px; 
            .commonHeadingRow {
                padding-bottom: 2rem;
            }

            .our-stories {
                margin-bottom: 30px;

                .stories-content {
                    padding-top: 20px;

                    .sub-heading {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .stories-video-sec-heading {
        .stories-heading-div {
            padding: 50px 20px 50px;

            h4 {
                font-size: 25px;
            }
        }
    }

    .stories-video-sec {

        // margin-bottom: 80px;
        // &::after{ 
        //     bottom: -80px; 
        // }
        .stories-video-container {
            .stories-video-row {
                padding: 5px 20px;

                .stories-content {
                    padding: 30px;
                }
            }
        }
    }

    .parallaxBG {
        height: 500px;

        &.donate-parallax {
            height: auto;

            .donate-parallax-container {
                .donate-parallax-content {
                    padding-top: 20px;

                    h5 {
                        font-size: 30px;
                        margin-bottom: 25px;
                    }
                }
            }

        }

        .hero-text {
            h2 {
                font-size: 3.5rem;

            }
        }
    }

    .home-parallax-sec {
        .parallaxBG {
            .hero-text {
                h2 {
                    font-size: 40px;
                    line-height: 80px;

                    &::after {
                        background: url('./../assets/bottom-line-img.png') no-repeat center center / 50% 50%;
                        bottom: -25px;
                        right: 350px;
                    }
                }

                p {
                    font-size: 20px;
                    margin-top: 20px;
                }
            }

        }
    }


    // Stories Inner page
    .border-bottom-div {
        border-bottom: 1px solid $blackColor;
    }


    .stories-inner-description-sec {

        // margin-bottom: 100px; 
        p {
            line-height: 30px;
        }

        .stories-desc-container {
            padding: 35px 0px 0px;

            .stories-border-row {
                .stories-row-div {
                    max-width: 1100px;
                    padding: 0px 65px 35px;

                    .story-inner-Row {
                        p {
                            margin-bottom: 0px;
                            font-weight: bold;
                            font-size: 17px;

                        }
                    }

                    .stories-inner-desc {
                        p {
                            line-height: 30px;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        .story-left-image {
            padding: 35px 35px 35px 35px;
            max-width: 1100px;

            &.donatedescription-container {

                // margin-bottom: 100px; 
                .stories-right-content {
                    padding: 50px 0px 0px 70px;
                }
            }

            &.story-left-image1 {
                padding: 35px 35px 0px 35px;
                max-width: 1100px;

            }

            .stories-right-content {
                padding: 0px 35px;

                h4 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    line-height: 30px;
                }
            }

            .stories-des-text {
                padding: 80px 50px;
            }

            .stories-des-text1 {
                padding: 80px 50px;
                max-width: 1100px;
                margin: 0 auto;
            }

            .stories-left-content {
                padding: 0px 80px 0px 0px;

                h4 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    line-height: 30px;
                }
            }

            .stories-right-content1 {
                padding: 0px 60px 0px 60px;
            }

            .stories-highlight-text-div1 {
                padding: 0px 0px 0px 85px;
                margin-top: 30px;

                h3 {
                    font-size: 1.75rem;
                    line-height: 40px;
                }
            }

            .stories-highlight-text-div {
                padding: 0px 80px;
                margin-left: 80px;
                margin-bottom: 80px;

                h3 {
                    font-size: 1.25rem;
                    line-height: 40px;
                }
            }
        }
    }


    .smilarstories-sec {

        // padding-bottom: 85px; 
        &:before {
            top: -90px;
        }

        .stories-row-div {
            max-width: 1100px;
            padding: 35px 20px 35px 20px;

            .our-stories {
                .stories-content {
                    padding-top: 25px;

                    .sub-heading {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    // Donate Page design
    .donateBanner {
        .donate-container {
            .banner-card-col {
                display: flex;
                justify-content: left;
                align-items: center;

                .banner-card {
                    padding: 30px 20px;

                    h6 {
                        font-size: 25px;
                    }

                    p {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
            }
        }

    }

    .donate-ratings-container {
        .donate-ratings-div {
            padding: 65px 0px 0px 0px;

            .donate-rating {

                // width: 80%;
                h5 {
                    font-size: 50px;
                    margin-bottom: 10px;
                }

                P {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    //  Donate Form 
    .donate-form {
        .donateForm {
            border: none;
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .give-currency-symbol {
                height: 50px;
            }

            .donateText {
                height: 50px;
            }
        }

        .amounts-list {
            margin: -10px;
            padding-bottom: 20px;
            padding-top: 10px;

            li {
                margin: 2px;

                button {
                    padding: 7px 15px;
                    font-size: 13px;
                }
            }
        }
    }

    // Get involved Page start
    .become-an-agent-sec {
        max-width: 100%;
        padding: 50px 0px 50px;

        .become-an-agent-description {
            padding: 35px;
            width: 100%;
        }
    }

    .get-involved-header {
        .commonHeadingRow {
            padding: 30px 0px 30px;
        }
    }


    .nav-wrap {
        padding: 0 10px;
        max-width: 100%;
        width: 100%;

        .wrp-inner {
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }

        .nav {
            .nav-item {
                height: 80px;
            }

        }
    }

    .get-involved-page-sec {
        max-width: 100%;

        .get-involved-image {
            padding-top: 30px;
        }

        &.career-form-sec {
            // padding-bottom: 80px;
        }

        .getinvolved-form {
            &.volunteers-form {
                padding: 30px 100px 30px;
            }

            &.fellowship-form {
                padding: 30px 100px 30px;
            }

            padding: 30px 30px;

            h6 {
                font-size: 25px;
                margin-bottom: 30px;
            }

            button {
                margin: 10px 20px;
            }
        }

    }

    .jobs-openings-sec {
        padding: 0px 0px 30px;

        &:before {
            height: 35%;
        }

        .jobssHeadingDiv {
            padding: 30px 0px;
        }

        .jobs-cards-div {
            max-width: 100%;
            width: 100%;
        }

        .jobs-card {
            padding: 30px;
            margin-bottom: 30px;
            max-width: 100%;

            h4 {
                margin-bottom: 20px;

            }
        }
    }

    // Get Involved FAQ sec
    .faqSec {
        position: relative;
        padding: 40px 0px;
        // padding-bottom: 80px;

        &:before {
            top: -90px;
        }

        .container-fluid {
            max-width: 100%;
        }

        .faqDescriptions {
            padding: 20px 0px;

            .accordion {
                .accordion-item {
                    padding: 15px 20px 15px;
                    margin-bottom: 25px;

                    h2 {
                        font-size: 20px;
                        margin-bottom: 0px;
                    }
                }

                .accordion-header {
                    button {
                        width: 100%;
                        margin: 0;
                        padding: 15px 0 0;
                        font-size: 17px;
                    }

                }

                .accordion-collapse {
                    p {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        margin-top: 20px;
                    }
                }
            }
        }

    }

    // Get Involved Partners Section 
    .partners-section {
        width: 100%;
        height: 100%;
        padding: 80px 0px 80px;

        .partners-row {
            .partners-description {
                padding-left: 80px;

                h4 {
                    font-size: 35px;
                    line-height: 50px;
                }
            }
        }
    }

    // Get involved Page End

    .home-donors-sec {

        // margin-bottom: 80px;
        .donors-sec {
            padding: 50px 20px 50px 20px;
        }

    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .about-image-bg {

        .stories-change-div {
            padding: 40px;

            h3 {
                font-size: 35px;
                font-family: 'Poppins-Bold';
            }

        }
    }

    // Home Page Responsiveness


    // Menu 
    // Rahul Navbar Style Start
    .rahul-navbar {
        &.navbar {
            padding: 10px;
        }

        &.fixed-top {
            // position: absolute;
        }

        &.bg-light {
            height: 100px;
            transition: all 0.5s ease;
        }

        &.navactive.bg-light {
            height: 75px;
            background-color: #ffffff !important;
            box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.102);
            transition: all 0.5s ease;

            .navbar-brand {
                img {
                    width: 200px;
                    transition: all 0.5s ease;
                }
            }
        }

        .navbar-brand {

            // background-color: $blueColor;
            // padding: 20px 10px;
            // margin-right: 0rem;
            img {
                width: 200px;
                transition: all 0.5s ease;
            }
        }

        .nav-link {
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 1px;
            font-family: 'Poppins-Regular';
            position: relative;
            text-transform: uppercase;
            padding: 10px 10px;

            &.yellowColorBtn {
                margin-left: 0px;
                font-size: 11px !important;
                padding: 1px 15px 1px 15px !important;
            }
        }

    }

    // Common Heading style
    .common-heading {
        line-height: 50px;
        font-size: 30px;
    }

    .common-para {
        font-size: 15px;
        line-height: 25px;
        font-family: 'Poppins-Regular';
    }

    .sub-heading {
        font-size: 20px;
        line-height: 25px;
    }

    // Common Heading
    .commonHeadingDiv {
        .commonHeadingRow {
            p {
                padding: 0px 0px;
            }
        }
    }

    .bannerSec {
        // margin-top: 60px; 

        .bannerHeight {
            margin: 0px 0px 75px 0px;
            .bannerRow {
                .bannerDiv {
                    h1 {
                        line-height: 60px;
                        font-size: 40px;
                    }

                    p {
                        line-height: 30px;
                        font-size: 20px;
                    }
                }

                &.col-md-7 {
                    flex: 0 0 85%;
                    max-width: 85%;
                }
            }
        }

    }

    // Home About Us Section
    .aboutus-sec {
        padding: 50px 0px;

        .aboutHeadingDiv {
            .aboutHeadingRow {
                @include flex (row, center, center);
                padding-bottom: 2rem;

                h1 {
                    margin-bottom: 1rem;
                }

                p {
                    padding: 0px 50px;
                }
            }
        }

        .aboutBottomDiv {
            padding: 0px 50px;

            .lightBGcard {
                padding: 30px;

                h3 {
                    font-size: 30px;
                }
            }

            .ratingsText {
                font-size: 80px;
                margin-bottom: 20px;
            }

            .redBGcard {
                padding: 40px 40px;
            }
        }

        .about-image-bg {
            .stories-change-div {
                padding: 50px;
                text-align: center;
                margin: 0 auto;
                z-index: 99;

                h3 {
                    font-size: 35px;
                    font-family: 'Poppins-Bold';
                }

            }
        }


    }

    // programs Section Style
    .programs-sec {
        position: relative;
        // margin-top: 100px;
        // padding: 0px 50px 100px 50px;
        padding: 60px 60px 60px 60px;


        &:before {
            top: -100px;
            height: 20%;
        }

        .commonHeadingDiv {
            .commonHeadingRow {
                padding: 0 0px;
            }
        }

        .OurProgramsSlidersDiv {
            padding: 30px 0px 0px 20px;
        }

        .programs-div {
            .programs-card {
                margin-bottom: 30px;

                .card-top-stripe {
                    font-size: 10px;
                    top: 7px;
                    left: 7px;
                }

                .card-content {
                    padding: 20px 15px;

                    h5 {
                        font-size: 17px;
                    }

                    .progress {
                        margin: 15px 0px;

                        .progress-bar {
                            background-color: $yellowColor;
                        }
                    }
                }

                .card-bg-content {
                    .card-content {
                        padding: 20px 15px;
                        margin-top: 40px;

                        h5 {
                            font-size: 17px;
                        }
                    }

                    .card-top-stripe {
                        margin: 10px 0px 0px 25px;
                    }
                }
            }
        }


    }

    .home-parallax-sec {
        .parallaxBG {
            background-image: url('./../assets/Education-01.jpg');

            .hero-text {
                h2 {
                    font-size: 40px;
                    line-height: 60px;
                    color: #ffffff;

                }
            }
        }
    }

    // Latest News Section Style
    .latestnews-sec {
        .newsHeadingDiv {
            padding-bottom: 1.5rem;
        }

        // margin-top: 50px;
        padding: 60px 60px 60px 60px;

        &.latest-news-inner {

            // padding-bottom: 150px;
            &:before {
                top: -80px;
            }
        }

        &:before {
            left: 0;
            top: -100px;
            width: 100%;
            height: 55%;
        }

        .news-content {
            padding: 20px 0px;

            .newsDate {
                text-align: right;
            }

            h5 {
                margin-bottom: 1rem;
                font-size: 15px;
                line-height: 25px;
            }

            p {
                font-size: 15px;
                margin-bottom: 0rem;
            }
        }

        .latest-news {
            .news-img {
                position: relative;
                width: 100%;
                height: 100%;

                &:after {
                    z-index: 0;
                    background-image: url('./../assets/latestnews-top-img.png');
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    content: '';
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: -10px;
                    width: 100%;
                    height: 50px;
                    opacity: 0;
                    transition: all .2s;
                }
            }

            &:hover {
                .news-img {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // Get Involved Section Style 
    .getinvolved-sec {
        position: relative;
        padding: 50px 0px 0px;

        .involved-sec {
            position: relative;
            padding: 50px 50px;
            width: 100%;

            .involved-div {
                .involved-card {
                    .involved-card-img {
                        position: relative;

                        .involved-card-content {
                            h6 {
                                padding: 25px 10px;
                                font-size: 15px;
                            }
                        }
                    }

                    .involved-para-content {
                        text-align: center;
                        padding: 10px;
                    }
                }
            }

        }
    }

    // RCT Footer Style
    .rct-footer-sec {
        .footerTopimg {
            top: -120px;
        }

        .rct-footer-div {
            padding: 50px 30px 50px;

            .col-md-2 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .footer-links {
                padding-top: 40px;

                p {
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .footer-address-div {
                padding-right: 0px;

                p {
                    margin-bottom: 0rem;
                }

                img {
                    margin-bottom: 1rem;
                    width: 140px;
                }

                .footer-social-links a {
                    margin-right: 25px;

                    &.fa::before {
                        font-size: 20px;
                    }
                }
            }

            .subscribe-form {
                padding-top: 30px;
                position: relative;

                &::before {
                    z-index: 0;
                    background-image: url('./../assets/footer-form.png');
                    background-position: right;
                    background-repeat: no-repeat;
                    background-size: 35%;
                    content: "";
                    position: absolute;
                    left: -60px;
                    top: -20px;
                    width: 100%;
                    height: 35%;
                }

                h2 {
                    font-size: 25px;
                    line-height: 35px;
                    margin-bottom: 0rem;
                    width: 90%;

                }

                .subscribeForm {
                    margin: 1rem 0rem;
                    width: 75%;

                    .form-control {
                        height: calc(2.5rem + 2px);
                        font-size: 12px;
                    }

                    .btn {
                        height: auto;
                        margin: 2px;
                        font-size: 10px;
                    }

                    .subscribeText {
                        padding-left: 1rem;
                    }
                }
            }
        }

        .rct-footer-copyright {
            padding: 30px 20px;

            .col-md-6 {
                &.text-left {
                    flex: 0 0 40%;
                    max-width: 40%;
                }
            }

            p {
                margin-bottom: 0rem;
            }

            .col-md-6 {
                &.text-right {
                    flex: 0 0 60%;
                    max-width: 60%;
                    padding: 0;
                }

                &.rct-footer-right {
                    p {
                        margin: 0px 7px;

                        &::after {
                            right: -10px;
                        }
                    }
                }
            }
        }
    }

    // Media Page Style
    .media-news-sec {
        &.latestnews-sec {
            padding: 0px 50px 150px 50px;
        }

        .commonHeadingDiv {
            margin-bottom: 10px;
        }

        .latest-news {
            margin-bottom: 15px;
        }
    }

    .news-top-circle {
        position: absolute;
        top: -30px;
        left: 30px;
        z-index: 3;

        .news-top-circle-div {
            height: 60px;
            width: 60px;
        }
    }

    // Media Inner Page Style

    // Left Social Sharing Button sticy 
    .social-sharing-buttons {
        margin: 10px -60px;
        left: 25px;
    }

    // Latest News Section Style
    .newslatestSlider {
        .swiper-wrapper {
            margin-top: 80px;
        }
    }

    // Programs Page Style
    .programs-sec {
        &.programs-page-sec {
            padding: 65px 70px 65px 70px;
            margin-top: 0px;

            .commonHeadingDiv {
                .commonHeadingRow {
                    justify-content: center;
                    padding: 0 40px;
                }
            }

        }
    }

    // Programs Inner page design
    .programs-inner-div {
        img {
            padding-bottom: 2rem;
        }
    }

    .programs-inner-form-sec {
        max-width: 90%;
        margin-top: -90px;

        .programs-inner-form-card {
            padding: 40px;
        }

        h4 {
            font-size: 1.8rem;
        }

        .program-price {
            font-size: 20px;
        }

        .select-payment-method {
            h6 {
                font-size: 23px;
                margin-bottom: 25px;
                width: 32%;
            }
        }

        .personal-info-div {
            h6 {
                font-size: 23px;
                margin-bottom: 25px;
                width: 20%;
            }
        }

        .donation-total-div {
            h6 {
                font-size: 24px;
                margin-bottom: 25px;
            }
        }
    }

    // Stories Page Style Start

    .stories-list-sec {
        .stories-row-div {
            padding: 65px 25px 65px;

            // margin-bottom: 100px; 
            .our-stories {
                margin-bottom: 50px;

                .stories-content {
                    padding-top: 30px;

                    .sub-heading {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .stories-video-sec-heading {
        .stories-heading-div {
            padding: 70px 20px 70px;

            h4 {
                font-size: 25px;
            }
        }
    }

    .stories-video-sec {

        // margin-bottom: 100px;
        // &::after{ 
        //     bottom: -100px; 
        // }
        .stories-video-container {
            .stories-video-row {
                padding: 25px 40px;

                .stories-content {
                    padding: 45px;
                }
            }
        }
    }

    // Stories Inner page
    .border-bottom-div {
        border-bottom: 1px solid $blackColor;
    }

    .stories-inner-description-sec {

        // margin-bottom: 120px; 
        p {
            line-height: 30px;
        }

        .stories-desc-container {
            padding: 50px 0px 0px;

            .stories-border-row {
                .stories-row-div {
                    max-width: 1100px;
                    padding: 0px 80px 80px;

                    .story-inner-Row {
                        p {
                            margin-bottom: 0px;
                            font-weight: bold;
                            font-size: 17px;

                        }
                    }

                    .stories-inner-desc {
                        p {
                            line-height: 30px;
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }

        .story-left-image {
            padding: 50px 50px 0px 50px;
            max-width: 1100px;

            &.donatedescription-container {
                margin-bottom: 120px;

                .stories-right-content {
                    padding: 0px 0px 0px 100px;
                }
            }

            &.story-left-image1 {
                padding: 50px 50px 0px 50px;
                max-width: 1100px;

            }

            .stories-right-content {
                padding: 0px 50px;

                h4 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    line-height: 30px;
                }
            }

            .stories-des-text {
                padding: 100px 60px;
            }

            .stories-des-text1 {
                padding: 100px 60px;
                max-width: 1100px;
                margin: 0 auto;
            }

            .stories-left-content {
                padding: 0px 100px 0px 0px;

                h4 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                    line-height: 30px;
                }
            }

            .stories-right-content1 {
                padding: 0px 80px 0px 80px;
            }

            .stories-highlight-text-div1 {
                padding: 0px 0px 0px 100px;
                margin-top: 40px;

                h3 {
                    font-size: 1.75rem;
                    line-height: 40px;
                }
            }

            .stories-highlight-text-div {
                padding: 0px 100px;
                margin-left: 100px;
                margin-bottom: 100px;

                h3 {
                    font-size: 1.25rem;
                    line-height: 40px;
                }
            }
        }
    }


    .smilarstories-sec {
        position: relative;

        // padding-bottom: 100px; 
        .stories-row-div {
            max-width: 1100px;
            padding: 50px 20px 50px 20px;

            .our-stories {
                .stories-content {
                    padding-top: 45px;

                    .sub-heading {
                        font-size: 22px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .donate-form {
        .donateForm {
            .give-currency-symbol {
                height: 70px;
            }

            .donateText {
                height: 70px;
            }
        }

        .amounts-list {

            li {
                button {
                    padding: 7px 10px;
                    font-size: 13px;
                }
            }
        }
    }

    // Get involved Page start
    .become-an-agent-sec {
        .become-an-agent-description {
            padding: 50px;
            width: 100%;
        }
    }

    .get-involved-inner-banner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .get-involved-header {
        .commonHeadingRow {
            padding: 65px 0px 40px;
            z-index: 9;
        }
    }

    .navtabactive {
        .nav-wrap {
            position: fixed;
            top: 80px;
            left: 0;
            right: 0;
            z-index: 999;
        }

        .progress {
            position: fixed;
            max-width: 1240px;
            width: 100%;
            top: 75px;
        }
    }

    .nav-wrap {
        padding: 0 50px;
        width: 100%;

        .wrp-inner {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .get-involved-page-sec {

        .getinvolved-form {
            h6 {
                font-size: 28px;
                margin-bottom: 40px;
            }
        }

    }

    .jobs-openings-sec {
        position: relative;
        padding: 0px 0px 50px;

        .jobssHeadingDiv {
            padding: 50px 0px;
        }

        .jobs-card {
            padding: 50px;
            margin-bottom: 40px;
            max-width: 100%;
        }
    }

    // Get Involved FAQ sec
    .faqSec {
        position: relative;
        padding: 50px 0px;

        // padding-bottom: 150px; 
        .faqDescriptions {
            padding: 30px 0px;

            .accordion {
                .accordion-item {
                    padding: 15px 40px 15px;
                    margin-bottom: 40px;

                    h2 {
                        font-size: 20px;
                        margin-bottom: 0px;
                    }
                }
            }
        }

    }

    // Get Involved Partners Section 
    .partners-section {
        width: 100%;
        height: 100%;
        padding: 100px 0px 150px;

        .partners-row {
            .partners-description {
                padding-left: 100px;

                h4 {
                    font-size: 35px;
                    line-height: 50px;
                }
            }
        }
    }

    // Get involved Page End

}

@media only screen and (min-width: 1281px) and (max-width:1370px) {
    .programs-sec .programs-div .programs-card .card-content {
        height: 350px; 
    }
    .news-content {
        h5 {
            font-size: 22px;
            line-height: 25px;
        }
    }

    // RCT Footer Style
    .rct-footer-sec {
        .rct-footer-div {
            .subscribe-form {
                h2 {
                    font-size: 25px;
                    line-height: 40px;

                }
            }
        }

        .rct-footer-copyright {
            .rct-footer-right {
                p {
                    margin: 0px 10px;

                    &::after {
                        right: -10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1371px) and (max-width:1440px) {}

@media only screen and (min-width:1536px) {}

@media only screen and (min-width: 1600px) and (max-width: 1823px) {
    .founder-story .scroll-line {
        height: 400px;
    }

    .navtabactive {
        .progress {
            max-width: 1375px;
        }
    }

    .progress {
        max-width: 1375px !important;
    }

    .nav-wrap {
        max-width: 1375px;
    }

    .get-involved-page-sec {
        max-width: 1375px;
    }
}

@media screen and (min-width: 1680px) {
    .founder-story .scroll-line {
        height: 400px;
    }

    .wrap {
        max-width: 1680px;
    }

    .involved-div {
        max-width: 1680px;
        margin: 0 auto;
    }

    .rct-footer-div {
        max-width: 1680px;
    }

    .rct-footer-copyright {
        max-width: 1680px;
    }


    .navtabactive {
        .progress {
            max-width: 1375px;
        }
    }

    .progress {
        max-width: 1375px !important;
    }

    .nav-wrap {
        max-width: 1375px;
    }

    .get-involved-page-sec {
        max-width: 1375px;
    }

}

@media only screen and (min-width: 1824px) {}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
    .founder-story .scroll-line {
        height: 400px;
    }


    .navtabactive {
        .progress {
            max-width: 1375px;
        }
    }

    .progress {
        max-width: 1375px !important;
    }

    .nav-wrap {
        max-width: 1375px;
    }

    .get-involved-page-sec {
        max-width: 1375px;
    }

    // Get Involved FAQ sec
    .faqSec {
        &:before {
            height: 40%;
        }
    }

    // Get Involved Partners Section 
    .partners-section {
        .partners-row {
            .partners-description {
                padding-left: 250px;
            }
        }
    }

    .rahul-navbar {
        .rahul-navBG {
            &.container {
                max-width: 1500px;
                padding: 0;
            }
        }
    }

    .bannerSec {
        height: 85vh;

        .bannerHeight {
            .bannerRow {
                margin-top: -150px;
            }
        }
    }

    // .OurProgramsSlidersDiv {
    //     &.programs-div {
    //         &.wrap {
    //             max-width: 100%;
    //         }
    //     }
    // }
    // .programs-sec {
    //     .OurProgramsSlidersDiv {
    //         padding: 50px 0px 0px 300px;
    //     }
    // }

    .stories-video-sec::after {
        height: 80%;
    }

}

@media only screen and (min-width: 2160px) and (max-width: 2560px) {
    .founder-story .scroll-line {
        height: 400px;
    }

    .bannerSec {
        height: 100vh;
    }

    .navtabactive {
        .progress {
            max-width: 1375px;
        }
    }

    .nav-wrap {
        max-width: 1375px;

    }

    .get-involved-page-sec {
        max-width: 1375px;


    }

    // Get Involved FAQ sec
    .faqSec {
        &:before {
            height: 40%;
        }
    }

    // Get Involved Partners Section 
    .partners-section {
        .partners-row {
            .partners-description {
                padding-left: 400px;
            }
        }
    }
}



// Animations 

// Heart Icon Animation

@-webkit-keyframes heart {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    10% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    15% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    55% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    60% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    65% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes heart {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    5% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    10% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    15% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    55% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    60% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }

    65% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}



// Btn Animation
@-webkit-keyframes animOpacite {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes animOpacite {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}


// Blinking Light Animation

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .7;
    }

    100% {
        opacity: 1;
    }
}

// Ripple Animation
$color: #00B398;

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($color, 0.3),
            0 0 0 0.7em rgba($color, 0.3),
    }

    100% {
        box-shadow: 0 0 0 0.7em rgba($color, 0.3),
            0 0 0 1em rgba($color, 0.3),
    }
}

//   Image Animation

@keyframes image-in {
    from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}


@-webkit-keyframes scrolling-loop {
    0% {
        transform-origin: top;
        transform: scaleY(0);
    }

    50% {
        transform-origin: top;
        transform: scaleY(1);
    }

    51% {
        transform-origin: bottom;
        transform: scaleY(1);
    }

    to {
        transform-origin: bottom;
        transform: scaleY(0);
    }
}

@keyframes scrolling-loop {
    0% {
        transform-origin: top;
        transform: scaleY(0);
    }

    50% {
        transform-origin: top;
        transform: scaleY(1);
    }

    51% {
        transform-origin: bottom;
        transform: scaleY(1);
    }

    to {
        transform-origin: bottom;
        transform: scaleY(0);
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(10px);
    }
}



//   Loading
.loading-image {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $whiteColor;
    border: none;
    position: relative;

    .loading-img {
        svg {
            display: none;
        }

        background: url('./../assets/RCT-Logo-loader-Anim-white-bg.gif');
        @include bg-cover;
        position: relative; 
        width: 350px !important;
        height: 350px !important;
        border:none;
        background-color: $whiteColor;
    }
}

// about us page responsiveness done 
// mobile from 320  to 768
@media only screen and (min-width:320px) and (max-width: 767.5px) {
    .news-inner-banner-bottom-pdf-container .news-inner-banner-bottom-pdf-row {
        text-align: center;
    }
    .pdf-img img {
        width: 15%;
    }
    .news-inner-banner-bottom .news-inner-banner-bottom-youtube iframe {
        width: 100%;
        height: 350px;
    }
    .news-inner-banner-bottom .news-inner-banner-bottom-video video {
        width: 100%;
        height: 350px;
    }
    .news-inner-banner-bottom .news-inner-banner-bottom-img img {
        width: 100%;
        height: 350px;
    }
    //   Loading
    .loading-image {
        .loading-img {
            width: 100px !important;
            height: 100px !important;
        }
    }

    // about us banner section heading 
    // .bannerSec {
    //     .bannerHeight {
    //         .bannerRow {
    //             .bannerDiv {
    //                 h1 {
    //                     font-size: 30px;
    //                     line-height: 45px;
    //                 }
    //             }
    //         }
    //     }
    // }
    // about us  -about section
    .about-us-about-section {
        padding: 40px 0px;
    }

    // ourvalues card section
    .our-values {
        padding: 40px 0px;

        .value-card-section {
            padding: 90px 0px 10px;
            margin-bottom: -25%;

            div {
                display: flex;
                justify-content: center;
            }

            .value-card {
                h2 {
                    font-size: 1rem;
                }

                background-color: #ffffff;
                padding: 5%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-bottom: 30px;
                width: 100%;
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }

    // aboutuspurple section
    .about-us-purple-section {
        &.common-sec {
            padding: 50px 30px;
        }

        &::before {
            top: -80px;
            width: 100%;
            height: 10%;
        }

        .about-green-container {
            max-width: 1200px;
            position: relative;
            margin: 0 auto;
            padding-bottom: 0px;

            .about-green-content {
                padding: 70px 0px 0px 0px;
                text-align: center;
            }

            .image-container {
                position: relative;
                @include flex (column, center, center);

                .image-1 {
                    position: absolute;
                    bottom: -50px;
                    left: 5px;
                    width: 35%;
                }

                .image-2 {
                    width: 50%;
                    padding-left: 10px;
                }

            }
        }
    }

    // <----======= donation section ==========---->
    .donation-section {
        .about-donate-top-div {
            .row {
                flex-direction: column-reverse;
            }
        }

        .donation-section-description {
            padding-top: 5%;
            text-align: center;

            h2 {
                width: 100%;
                text-align: center;
            }

            p {
                width: 100%;
            }
        }

        .donor-heart-image {
            max-width: 900px;

            img {
                width: 100%;
                position: relative;
                top: -10px;
            }
        }

        .donation-count {
            @include flex(row, center, flex-start);
        }

        .donation-value {
            // max-width: 300px;
            // margin-right: 100px;
            z-index: 9;
        }

        .donation-number {
            font-size: 4.5rem;
            color: $blueColor;
        }

    }

    .donate-ratings-container {
        .donate-ratings-div {
            padding: 30px 0px 0px 0px;
            text-align: center;

            .donate-rating {
                h5 {
                    font-size: 40px;
                }

                p {
                    font-size: 22px;
                }
            }
        }
    }

    .aboutHeadingDiv {
        text-align: center;
        padding-top: 20px;
    }


    // founder story responsiveness
    .founder-story {

        #slider-7 .row {
            flex-direction: column-reverse;
        }


        h2,
        h5 {
            width: 100%;
        }

        text-align: center;

        // margin-bottom: 50px;
        .founder-story-description {
            padding: 0 20px;

            p {
                padding-left: 0px;
            }
        }

        .founder-story-description1 {
            padding-left: 20px;
            padding-right: 20px;

            p {
                padding-left: 0px;
            }
        }

        .founder-story-description2 {
            padding: 0 20px;

            h2 {
                padding-left: 0px;
            }
        }

        .founder-circle-row {
            display: none;
        }

        .founder-circle-div {
            margin-bottom: 10%;
            display: none;
        }

        .founder-pic {
            padding: 10px 20px;
            width: 100%;

            h2 {
                font-size: 30px;
                left: -5px;
                padding: 10px 20px;
            }
        }

        .founder-story-description3 {
            h5 {
                width: 100%;
            }

            text-align: center;
            padding: 0 20px;

            img {
                width: 100%;
            }
        }

        .founder-story-description4 {
            width: 50%;
            padding-left: 20px;
        }

        .founder-story-description5 {
            text-align: center;
            display: block;

            // .founder-pic{
            //     width: 80%;
            //     position: relative;
            //     top: 0px;
            //     left: 0%;
            // }
            .founder-pic {
                padding: 10px 20px;
            }

            .founder-pic1 {
                h2 {
                    margin-top: 5%;
                }

                top: -75px;
                width: 60%;
                left: 35%;
                display: block;
                padding: 10px 20px;
                position: relative;
            }
        }

        .founderSwipers {
            .swiper-button-next {
                cursor: pointer;
                right: 25px;
            }

            .swiper-button-prev {
                right: 90px;
            }
        }

        .founder-story-description-final3 {
            text-align: center;
            display: flex;
            justify-content: center;

            p {
                text-align: center;
                position: relative;
                width: 75%;
                top: 0%;
                right: 0%;
                /* right: 80%; */
            }

            .common-heading {
                text-align: center;
                position: absolute;
                right: 0%;
                width: 100%;
                top: -19%;
            }
        }

        .founder-story-description-final2 {
            .founder-pic {
                text-align: center;
                position: relative;
                top: 0px;
                left: 0px;
                width: 100%;
            }
        }

        .founder-story-description-final1 {
            text-align: center;
            padding: 20px 20px;
        }

        #slider-7 .row {
            flex-direction: column-reverse;
        }



    }

}

@media only screen and (min-width: 320px) and (max-width: 450px) {

    // ourvalues card section
    .our-values {
        .value-card-section {

            .value-card {
                margin-bottom: 25%;
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 768px) {


    // aboutuspurple section
    .about-us-purple-section {
        .about-green-container {
            .image-container {
                .image-1 {
                    left: 35px;
                    width: 30%;
                }

                .image-2 {
                    width: 50%;
                    padding-left: 5px;
                }

            }
        }
    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .about-donate-top-div {
        .row {
            flex-direction: column-reverse;

            .col-md-5,
            .col-md-7 {
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                margin: 0 auto;
            }

        }
    }

    .donation-section .donation-section-description {
        padding-top: 15px;
        align-items: center;
    }

    .about-us-purple-section .about-green-container .image-container .image-2 {
        width: 85%;
        padding-left: 60px;
    }

    .privacy-policy-sec {
        padding: 40px 0px 40px;
    }

    // .bannerSec {
    //     .bannerHeigh {
    //         .bannerRow {
    //             .bannerDiv {

    //                         h1{
    //                             font-size: 40px;
    //                             line-height: 65px;
    //                         }
    //                         &.col-md-7 {
    //                             flex: 0 0 85%;
    //                             max-width: 85%;
    //                         }
    //             }


    //         }
    //     }
    // }
    .founder-story-description-final2 {
        .founder-pic {
            text-align: center;
            position: relative;
            top: 0px;
            left: 0px;
            width: 100%;
        }
    }

    .founder-story {
        .founder-circle-row {
            .founder-circle-div {
                .founder-circle {
                    width: 80px;
                    height: 80px;

                    &::after {
                        left: 80px;
                    }
                }
            }
        }

        .scroll-line {
            height: 200px;
        }
    }

    .about-us-purple-section {
        &.common-sec {
            padding: 50px 50px 50px 50px;
        }

        .about-green-container {
            padding-bottom: 20px;

            .about-green-content {
                padding: 0px 10px;
            }
        }
    }

    .donation-section {
        padding: 0px 30px;
    }

    .donate-ratings-container {
        .donate-ratings-div {
            padding: 50px 0px 50px 0px;

            .donate-rating {
                p {
                    font-size: 15px;
                    line-height: 25px;
                }

                h5 {
                    font-size: 50px;
                }
            }
        }
    }

    .about-us-about-section {
        padding: 75px 40px;
    }

    .our-values {
        padding: 50px 30px 0px 30px;

        .value-card-section .value-card {
            margin-bottom: 15%;
            height: 200px;
        }

        .value-card-section {
            padding: 30px 0px 30px;
        }
    }

    .founder-story {

        // margin-bottom: 100px;
        .founder-story-description1 {
            padding-right: 0px;
        }

        .founder-story-description2 {
            h2 {
                padding-left: 25px;
            }
        }

        .founder-story-description3 {
            padding: 0px 11px;

        }

        .founder-story-description-final2 .founder-pic {
            position: relative;
            top: 110%;
            width: 100%;
            left: 0%;
        }

        .founder-story-description-final3 {
            p {
                position: relative;
                width: 100%;
                right: 0%;
            }

            .common-heading {
                position: relative;
                right: 0%;
                width: 100%;
                top: 50%;
            }

        }

        .founder-story-description {
            padding-left: 40px;

            h2 {
                font-size: 25px;
                line-height: 40px;
            }

            p {
                padding-left: 0px;
            }
        }

        .founder-pic {
            h2 {
                font-family: "Poppins-Regular";
                font-size: 30px;
            }
        }
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .privacy-policy-sec {
        padding: 50px 0px 50px;
    }

    // .bannerSec {
    //     .bannerHeigh {
    //         .bannerRow {
    //             .bannerDiv { 
    //                         h1{
    //                             font-size: 50px;
    //                             line-height: 70px;
    //                         }
    //                         &.col-md-7 {
    //                             flex: 0 0 85%;
    //                             max-width: 85%;
    //                         }
    //             } 

    //         }
    //     }
    // }
    .parallaxBG.donate-parallax .donate-parallax-container {

        padding: 70px 40px 70px 40px;
    }

    .about-us-purple-section {
        &.common-sec {
            padding: 70px 40px 70px 40px;
        }

        .about-green-container {
            padding-bottom: 30px;

            .about-green-content {
                padding: 0px 10px;
            }
        }
    }

    .donation-section {
        padding: 80px 30px 0px 30px;
    }

    .donate-ratings-container {
        .donate-ratings-div {
            padding: 50px 30px;

            .donate-rating {
                p {
                    font-size: 15px;
                    line-height: 25px;
                }

                h5 {
                    font-size: 50px;
                }
            }
        }
    }

    .about-us-about-section {
        padding: 75px 40px;
    }

    .our-values {
        padding: 50px 30px 0px 30px;
    }


    .founder-story {

        // margin-bottom: 180px;
        .founder-story-description1 {
            padding-right: 0px;
        }

        .founder-story-description2 {
            h2 {
                padding-left: 25px;
            }
        }

        .founder-story-description3 {
            padding: 0px 11px;
        }

        .founder-story-description {
            padding-left: 40px;

            h2 {
                font-size: 25px;
                line-height: 40px;
            }

            p {
                padding-left: 0px;
            }
        }

        .founder-pic {
            h2 {
                font-family: "Poppins-Regular";
                font-size: 30px;
            }
        }
    }

}

@media only screen and (min-width: 320px) and (max-width: 767.5px) {

    // Privacy Policy Page responsiveness done by saurav
    .privacy-policy-sec {
        padding: 20px 0px 20px;

        h3 {
            text-align: center;
        }

        h5 {
            text-align: center;
        }

        p {
            text-align: center;
        }
    }


    // Contact Us Page Start
    .other-ways-contact-sec {
        padding: 0px 0px 15px;

        .contactHeadingDiv {
            .common-heading {

                margin: 20px 0px;
            }
        }

        .other-ways-contact-div {

            .circle-icon {
                .icon {
                    width: 72px;
                    height: 72px;

                    svg {
                        width: 30px;
                        height: 30px;

                    }
                }
            }

            .desc-div {
                width: 100%;
                margin: 0 auto;
            }

            .desc-mobile-div {
                width: 70%;
                margin: 0 auto;
            }
        }
    }

    // Contact Us Page End

    //getinvolved form
    .get-involved-page-sec {
        .getinvolved-form {
            .form-control {
                height: calc(2rem + 2px);

            }

            padding: 15px;
        }
    }

}










.custom-input-file input {
    display: none !important;
}

input[type="file"] {
    display: none !important;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .donateBanner .donate-container .banner-card-col.col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .donateBanner .donate-container .banner-card-col.col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}


.captcha-error {
    padding-bottom: 10px !important;
}

.Filename {
    display: flex;
    text-align: left;
    color: $greenColor;
}

.lightBox {
	div {
		z-index: 9999!important;
		background-color: #000!important; 
		// opacity: 0.8;
	}
    
    // iframe {
    //     width: 100%!important;
    //     height: 100%!important;
    // }
    iframe img {
        width: 100%!important;  
    }
}
.news-inner-images{
    img {
        width: 100%;
    }
    .news-inner-col {
        padding-bottom: 20px;
        cursor: pointer;
    }
} 
.lightBox iframe { 
    height: 100%!important; 
    width: 500px!important;
    margin: 0 auto!important; 
}
.lightBox iframe img{ 
    height: 100%!important; 
    // width: 100%!important;
    margin: 0 auto!important; 
}

.myClass {
    .fixed-top {
        position: relative; 
        display: none;
    }
    .social {
        z-index: 1;
    }
}
 

 .video-icon {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .video-fa-icon {
      background: $yellowColor;
      display: inline-block;
      vertical-align: middle;
      width: 45px;
      height: 45px;
      text-align: center;
      font-size: 20px;
      color: $whiteColor;
      float: left;
      border-radius: 100%;
      line-height: 45px;
      z-index: 9;
      position: relative;
  }
  .video-icon svg {
    fill: $whiteColor;
    width: 15px;
    height: 15px;
  }
  
